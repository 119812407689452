import React, { useEffect, useState, useContext } from "react";
import * as S from "../../style/styles";

import { GlobalContext } from "../contexts/GlobalState";
import {
  createUserBankAccount,
  deleteUserBankAccount,
  editUserBankAccount,
} from "../../services/userProfileRequests";

import { Loader } from "../elements/Loader";
import ModalForm from "../modals/ModalForm";
import { FormEditOrCreateBankAccount } from "../forms/BankAccountForm";
import { SwalButton } from "../buttons";

const BankAccount = ({ active, userId, isLoading }) => {
  const { states, setters } = useContext(GlobalContext);
  const { userBankAccount, isClient } = states;
  const { setUserBankAccount } = setters;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tabValue = 1;
  const isActive = active === tabValue;
  const [currentBank, setCurrentBank] = useState({});
  const [formAction, setFormAction] = useState(() => createUserBankAccount);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const setEditAction = (bankAccount) => {
    setFormAction(() => editAction);
    setCurrentBank(bankAccount);
    setIsModalOpen(true);
  };

  const setCreateAction = () => {
    setFormAction(() => creatAction);
    setCurrentBank({});
    setIsModalOpen(true);
  };

  const creatAction = (values) => {
    createUserBankAccount(userId, afterSubmitForm, values);
  };

  const editAction = (values) => {
    editUserBankAccount(userId, afterSubmitForm, values);
  };

  const destroyAction = (bankAccount) => {
    deleteUserBankAccount(userId, afterSubmitForm, bankAccount.id);
  };

  const afterSubmitForm = (data) => {
    setIsModalOpen(false);
    setUserBankAccount(data);
  };

  return (
    <S.DisplayContainer isActive={isActive} isLoading={isLoading}>
      <Loader />

      <div className="content-display">
        <div className="card mb-5 mb-xl-10">
          <div className="card-header  border-bottom border-gray-200 pt-5">
            <div className="card-title align-items-start flex-column">
              <h3 className="fw-bolder m-0">Dados Bancários</h3>
            </div>
            <div className="card-toolbar">
              {isClient ? (
                ""
              ) : (
                <button
                  type="button"
                  className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                  onClick={setCreateAction}
                >
                  <i className="bi bi-plus fs-3"></i>
                </button>
              )}
            </div>
          </div>

          <div className="card-body p-9 collapse show">
            <div className="">
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                  <tr className="fw-bold fs-6 text-gray-800">
                    <th>Banco</th>
                    <th>Agência</th>
                    <th>Conta</th>
                    <th>Gerente</th>
                    <th>E-mail</th>
                    <th>Telefone</th>
                    {isClient ? "" : <th className="text-end">Ações</th>}
                  </tr>
                </thead>
                <tbody>
                  {userBankAccount.map((item, index) => (
                    <tr key={index}>
                      <td>{item.bank.label}</td>
                      <td>{item.agency}</td>
                      <td>{item.account}</td>
                      <td>{item.bank_manager_attributes.name}</td>
                      <td>{item.bank_manager_attributes.email}</td>
                      <td>{item.bank_manager_attributes.masked_phone}</td>
                      <td className="text-end">
                        {isClient ? (
                          ""
                        ) : (
                          <div className="me-0">
                            <button
                              className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                              onClick={() => setEditAction(item)}
                            >
                              <i className="bi bi-pencil-square fs-7"></i>
                            </button>
                            <SwalButton
                              className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                              onSuccess={() => destroyAction(item)}
                              swalProps={{
                                title: "Você deseja excluir a conta bancária?",
                              }}
                            >
                              <i className="bi bi-trash fs-7"></i>
                            </SwalButton>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ModalForm
        isOpen={isModalOpen}
        toggle={toggleModal}
        title="Dados Bancários"
      >
        <FormEditOrCreateBankAccount
          objectState={currentBank}
          onFormSubmit={formAction}
          closeModal={() => toggleModal()}
        />
      </ModalForm>
    </S.DisplayContainer>
  );
};

export default BankAccount;
