import { showSwalDialog } from "../utils/swal";
import $ from "jquery";

export default () => {
  const button = $(".submit-confirm");
  if (!button.length) return false;

  button.on("click", (event) => {
    if (event.target.classList.contains("submit-confirm")) {
      event.preventDefault();
      showSwalDialog(event.target);
    }
  });
};
