import React, { useEffect, useState, useContext, Fragment } from "react";
import * as S from "../../style/styles";
import { GlobalContext } from "../contexts/GlobalState";
import { Loader } from "../elements/Loader";
import TableRemoteContent from "../tables/TableRemoteContent";
import ModalForm from "../modals/ModalForm";
import Chart from "react-apexcharts";

import LiquidityDetail from "../elements/LiquidityDetails";
import { Card, Col, Row } from "reactstrap";
import { formatReal } from "../../utils/currency";

const Liquidity = ({ active, userId, isLoading }) => {
  const { states, setters } = useContext(GlobalContext);
  const { userLiquidity } = states;
  const tabValue = 5;
  const isActive = active === tabValue;
  const [investmentList, setInvestmentList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const chartSetting = {
    series: userLiquidity.investments.map((e) =>
      parseFloat(e.value.value_without_mask)
    ),
    options: {
      labels: userLiquidity.investments.map((e) => `D+ ${e.liquidity}`),
    },
  };

  const setDetails = (items) => {
    setInvestmentList(items);
    setIsModalOpen(true);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <S.DisplayContainer isActive={isActive} isLoading={isLoading}>
      <Loader />

      <div className="content-display">
        <TableRemoteContent cardTitle="Liquidez" isEditable={false}>
          <Fragment>
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <tbody>
                <tr>
                  <td>Liquidez</td>
                  <td>Total</td>
                  <td className="text-end">Ações</td>
                </tr>
                {userLiquidity &&
                  userLiquidity.investments.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>D+{item.liquidity}</td>
                        <td>{item.value.value}</td>
                        <td className="text-end">
                          <button
                            className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                            onClick={() => setDetails(item.value.investments)}
                          >
                            <i className="bi bi-list fs-7"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <tfoot>
                <Row>
                  <Col>
                    <Card>
                      <Chart
                        options={chartSetting.options}
                        series={chartSetting.series}
                        type="pie"
                        width={500}
                        height={320}
                      />
                    </Card>
                  </Col>
                </Row>
              </tfoot>
            </table>
            <ModalForm isOpen={isModalOpen} toggle={toggleModal} label="Ativos">
              <LiquidityDetail
                items={investmentList}
                toggleModal={() => toggleModal()}
              />
            </ModalForm>
          </Fragment>
        </TableRemoteContent>
      </div>
    </S.DisplayContainer>
  );
};

export default Liquidity;
