import RemoteContent from './remote_content'


export default class extends RemoteContent {
  

  contentTemplate(kind, index) {
      return(
        `<tr key=${index}>
          <td>${ kind.name }</td>
          <td>${ kind.amount }</td>
          <td>${ kind.percentage }</td>
        </tr>`
      )
  }
}