import { Controller } from "@hotwired/stimulus"
import { moneyMask } from '../forms/masks' 

export default class extends Controller {
  static targets = ["links", "template", 'row']

  connect() {
    $('#amount-tables').on("cocoon:after-insert", function(_, row){
      moneyMask()
    })
  }

  add_association(event) {

    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())

    this.linksTarget.insertAdjacentHTML('beforeend', content)
  }
}