import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, ModalBody, ModalFooter } from "reactstrap";
import { Formik, Form } from "formik";

import { GlobalContext } from "../contexts/GlobalState";
import { Label, FormGroup, Number, Datepicker } from "./controls";
import investmentTransactionSchema from "../../schemas/investmentTransaction.schema";
import { Loader } from "../elements/Loader";
import { SaveButton } from "../../style/styles";

const defaultValues = {
  id: null,
  pu: 0,
  total: 0,
  day: "",
};

const InvestmentTransactionForm = ({
  objectState,
  onFormSubmit,
  closeModal,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const initialValues =
    Object.keys(objectState).length !== 0
      ? {
        ...objectState,
        total: objectState.total.replace("R$ ", ""),
        pu: objectState.pu.replace("R$ ", ""),
      }
      : defaultValues;

  const { states, setters } = useContext(GlobalContext);

  const onSubmit = (values) => {
    setIsLoading(true)
    onFormSubmit(values, setIsLoading);
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={investmentTransactionSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <ModalBody className="container p-10">
            <Row>
              <Col md={4}>
                <FormGroup name="day">
                  <Label htmlFor="day" required>
                    Data
                  </Label>
                  <Datepicker name="day" id="day" restrictDates={true} />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup name="total">
                  <Label htmlFor="total" required>
                    Valor
                  </Label>
                  <Number name="total" id="total" />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup name="pu">
                  <Label htmlFor="pu" required>
                    PU
                  </Label>
                  <Number name="pu" id="pu" />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}
            >
              Fechar
            </button>
            <SaveButton type="submit" className="btn btn-primary">
              {isLoading ?
                <Loader />
                :
                'Salvar'}
            </SaveButton>
          </ModalFooter>
        </Form>
      </Formik>
    </Fragment>
  );
};
export default InvestmentTransactionForm;
