
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['destroyButton']

 
  remove_association(event) {
    event.preventDefault()   
    let wrapper = event.target.closest(".nested-fields")
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = "none"

  }
}
