import React from "react";

export default ({ label, ...props }) => {
  return (
    <div
      key={props.id}
      className="form-check form-check-custom form-check-solid me-5"
    >
      <input className="form-check-input" type="radio" {...props} />
      <label className="form-check-label" htmlFor={props.id}>
        {label}
      </label>
    </div>
  );
};
