import React, { Fragment } from "react";
import Swal from "sweetalert2/dist/sweetalert2";
import axios from "axios";
import { OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { Input, FormGroup, Label, Number, InputMask } from "../forms/controls";
import { schemaSingUp } from "../../schemas/singUp";

export default function Leads() {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Informar a razão social quando o cadastro for de pessoa jurídica
    </Tooltip>
  );
  const onSubmit = async (values) => {
    await axios
      .post("/leads", values)
      .then(() =>
        Swal.fire({
          title: "Cadastro Realizado com sucesso!",
          text: "Agora e so aguardar o contato da nossa equipe",
          icon: "success",
          showConfirmButton: true,
          customClass: {
            confirmButton: "btn btn-primary",
          },

          confirmButtonText: "Confirmar",
        }).then(() => (window.location.href = "/login"))
      )
      .catch((error) => console.error(error));
  };
  return (
    <Fragment>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          name: "",
          phone: "",
          ddi: "",
          email: "",
          investment_amount: "",
        }}
        validationSchema={schemaSingUp}
      >
        <div className="d-flex flex-center flex-column flex-lg-row-fluid p-10">
          <div className="w-lg-500px ">
            <Form
              className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
              noValidate="novalidate"
              id="kt_sign_up_form"
            >
              <div className="text-center mb-11">
                <h1 className="text-dark fw-bolder mb-3">Cadastro</h1>
              </div>

              <div className="separator separator-content my-14"></div>

              <div className="fv-row mb-8 fv-plugins-icon-container">
                <FormGroup name="email">
                  <Label htmlFor="email" required>
                    Email
                  </Label>
                  <Input
                    name="email"
                    id="email"
                    type="text"
                    placeholder="Email"
                    autoComplete="off"
                    className="form-control bg-transparent"
                  />
                </FormGroup>

                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>
              <div className="fv-row mb-8 fv-plugins-icon-container">
                <FormGroup name="name">
                  <Label htmlFor="name" required>
                    Nome ou Razão social
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <i
                        className="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="tooltip"
                        aria-label="Esse campo so deve ser informado para cadastros de pessoa jurídica"
                        data-kt-initialized="1"
                      ></i>
                    </OverlayTrigger>
                  </Label>
                  <Input
                    name="name"
                    id="name"
                    type="text"
                    placeholder="Nome"
                    autoComplete="off"
                    className="form-control bg-transparent"
                  />
                </FormGroup>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>
              <div className="fv-row mb-8 fv-plugins-icon-container">
                <Row className="my-3" data-kt-stepper-element="content">
                  <Col md={6}>
                    <FormGroup name="ddi">
                      <Label htmlFor="ddi" required>
                        DDI
                      </Label>
                      <Input
                        name="ddi"
                        id="ddi"
                        placeholder="+55"
                        autoComplete="off"
                        className="form-control bg-transparent"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup name="phone">
                      <Label htmlFor="phone" required>
                        Telefone
                      </Label>
                      <InputMask
                        name="phone"
                        id="phone"
                        placeholder="Telefone"
                        autoComplete="off"
                        className="form-control bg-transparent"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="fv-row mb-8 fv-plugins-icon-container">
                <FormGroup name="investment_amount">
                  <Label htmlFor="investment_amount" required>
                    Valor Investimento
                  </Label>
                  <Number
                    name="investment_amount"
                    id="investment_amount"
                    placeholder="Valor do investimento"
                    autoComplete="off"
                    className="form-control bg-transparent"
                  />
                </FormGroup>

                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-grid mb-10">
                <button
                  type="submit"
                  id="kt_sign_up_submit"
                  className="btn btn-primary"
                >
                  <span className="indicator-label">Cadastrar</span>
                  <span className="indicator-progress">
                    Carregando...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>

              <div className="text-gray-500 text-center fw-semibold fs-6">
                Ja possui uma conta?
                <a href="/login" className="link-primary fw-semibold m-1">
                  login
                </a>
              </div>
              <div></div>
            </Form>
          </div>
        </div>
      </Formik>
    </Fragment>
  );
}
