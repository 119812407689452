import React, { useState, useContext } from "react";
import * as S from "../../style/styles";

import { GlobalContext } from "../contexts/GlobalState";

import { Loader } from "../elements/Loader";
import ModalForm from "../modals/ModalForm";
import TableRemoteContent from "../tables/TableRemoteContent";

import ExtractForm from "../forms/ExtractForm";
import {
  createUserExtract,
  deleteUserExtract,
} from "../../services/userProfileRequests";
import { SwalButton } from "../buttons";

const Extract = ({ active, userId, isLoading }) => {
  const { states, setters } = useContext(GlobalContext);
  const { userExtracts } = states;
  const { setUserExtracts } = setters;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tabValue = 11;
  const isActive = active === tabValue;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const creatAction = (values) => {
    createUserExtract(userId, afterSubmitForm, values);
  };

  const destroyAction = (document) => {
    deleteUserExtract(userId, afterSubmitForm, document.id);
  };

  const afterSubmitForm = (data) => {
    setIsModalOpen(false);
    setUserExtracts(data);
  };

  return (
    <S.DisplayContainer isActive={isActive} isLoading={isLoading}>
      <Loader />

      <div className="content-display">
        <TableRemoteContent
          cardTitle="Extratos"
          isEditable={true}
          actionType="create"
          setIsModalOpen={setIsModalOpen}
        >
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bold fs-6 text-gray-800">
                <th>Arquivo</th>
                <th className="text-end">Ações</th>
              </tr>
            </thead>
            <tbody>
              {userExtracts.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{value.name}</td>
                    <td className="text-end">
                      <a
                        href={value.attachment_url}
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      >
                        <i className="bi bi-download fs-7"></i>
                      </a>
                      <SwalButton
                        className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                        onSuccess={() => destroyAction(value)}
                        swalProps={{
                          title: "Você deseja excluir o extrato?",
                        }}
                      >
                        <i className="bi bi-trash fs-7"></i>
                      </SwalButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableRemoteContent>

        <ModalForm
          isOpen={isModalOpen}
          toggle={toggleModal}
          label="Upload de Extratos"
        >
          <ExtractForm
            onFormSubmit={creatAction}
            closeModal={() => toggleModal()}
          />
        </ModalForm>
      </div>

    </S.DisplayContainer>
  );
};

export default Extract;
