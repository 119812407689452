import ApexCharts from "apexcharts";
import { formatReal } from "../utils/currency";

export const CustomApexCharts = (element, data, kind, config) => {
  var chart = new ApexCharts(element, chartConfig(kind, data, config));
  chart.render();
};

const chartConfig = (kind, data, config) => {
  return {
    dataLabels: {
      enabled: false,
    },
    chart: {
      type: kind,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
    },
    series: data,
    xaxis: {
      type: "category",
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return formatReal(value);
        },
      },
      ...config,
    },
  };
};
