import React, { useState } from "react";
export const GlobalContext = React.createContext();

export const GlobalState = (props) => {
  const [userPersonalData, setUserPersonalData] = useState({});
  const [userBankAccount, setUserBankAccount] = useState([]);
  const [userHistory, setUserHistory] = useState({});
  const [userPerformanceHistory, setUserPerformanceHistory] = useState({});
  const [userInvestments, setUserInvestments] = useState({ investments: [] });
  const [userLiquidity, setUserLiquidity] = useState({ investments: [] });
  const [userTypesOfInvestment, setUserTypesOfInvestment] = useState([]);
  const [userAllocationProfile, setUserAllocationProfile] = useState([]);
  const [userIncome, setUserIncome] = useState({});
  const [userIssues, setUserIssues] = useState([]);
  const [userDocuments, setUserDocuments] = useState([]);
  const [userExtracts, setUserExtracts] = useState([]);
  const [banks, setBanks] = useState([]);
  const [investmentTemplates, setInvestmentTemplates] = useState([]);
  const [historyPayments, setHistoryPayments] = useState([]);
  const [clientHistory, setClientHistory] = useState([]);
  const [yearHolidays, setYearHolidays] = useState([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [isClient, setIsClient] = useState(props.isClient);

  const states = {
    userPersonalData,
    userBankAccount,
    userHistory,
    userPerformanceHistory,
    userInvestments,
    userLiquidity,
    userTypesOfInvestment,
    userAllocationProfile,
    userIncome,
    userIssues,
    userDocuments,
    userExtracts,
    banks,
    investmentTemplates,
    historyPayments,
    clientHistory,
    yearHolidays,
    isLoadingHistory,
    isClient,
  };
  const setters = {
    setUserPersonalData,
    setUserBankAccount,
    setUserHistory,
    setUserPerformanceHistory,
    setUserInvestments,
    setUserLiquidity,
    setUserTypesOfInvestment,
    setUserAllocationProfile,
    setUserIncome,
    setUserIssues,
    setUserDocuments,
    setUserExtracts,
    setBanks,
    setInvestmentTemplates,
    setHistoryPayments,
    setClientHistory,
    setYearHolidays,
    setIsLoadingHistory,
    setIsClient,
  };

  return (
    <GlobalContext.Provider value={{ states, setters }}>
      {props.children}
    </GlobalContext.Provider>
  );
};
