import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["messageList"];
  sendMessage(event) {
    $("#chat-messages")
      .stop()
      .animate(
        {
          scrollTop: $("#chat-messages")[0].scrollHeight,
        },
        800
      );
    event;
  }
}
