import RemoteContent from './remote_content'


export default class extends RemoteContent {
  static values = { 
    successMessage: {
      type: String,
      default: 'Upload do documento concluído com sucesso'
    }
  }

  contentTemplate(value, index) {
      return (
        `<tr>
          <td>${ value.translated_document_type }</td>
          <td class='text-end'>
            <a href=${value.attachment_url} class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
              <i class="fas fa-download"></i>
            </a>
            ${this.destroyResourceButton(`/services/users/${value.user_id}/documents/${value.id}`, 'documents' )}  
          </td>
        </tr>`
      )
  }
}

