import React, { useEffect, useState, useContext, Fragment } from "react";
import { editChargeMethot } from "../../services/userProfileRequests";
import * as S from "../../style/styles";
import { GlobalContext } from "../contexts/GlobalState";
import { Loader } from "../elements/Loader";
import TableRemoteContent from "../tables/TableRemoteContent";

const Payments = ({ active, userId, isLoading }) => {
  const { states, setters } = useContext(GlobalContext);
  const { historyPayments } = states;
  const tabValue = 12;
  const isActive = active === tabValue;

  const alterChargeMethod = () => {
    editChargeMethot(userId);
  };

  return (
    <S.DisplayContainer isActive={isActive} isLoading={isLoading}>
      <Loader />

      <TableRemoteContent
        cardTitle="Pagamentos"
        isEditable={true}
        actionType={"altercharge"}
        swalFunction={alterChargeMethod}
        className="content-display"
      >
        <Fragment>
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <tbody>
              <tr>
                <td>ID</td>
                <td>Status</td>
                <td>Valor</td>
                <td>Data</td>
                <td>Erro</td>
              </tr>
              {historyPayments &&
                historyPayments.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.pagar_me_id}</td>
                      <td>{item.status}</td>
                      <td>{item.transction_amount}</td>
                      <td>{item.updated_at}</td>
                      <td>{item.pagarme_error}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Fragment>
      </TableRemoteContent>
    </S.DisplayContainer>
  );
};

export default Payments;
