import React, { useState, useRef, useEffect, useContext } from "react";
import { Input } from "reactstrap";
import { useField } from "formik";
import $ from "jquery";
import moment from "moment";
import "daterangepicker";
import "moment/locale/pt-br";
import Cleave from "cleave.js";
import { GlobalContext } from "../../contexts/GlobalState";


const defaultFormat = "DD/MM/YYYY";

const Datepicker = ({ name, restrictDates, ...props }) => {
  const { states } = useContext(GlobalContext);
  const [field, , helpers] = useField({ name });
  const [inputValue, setInputValue] = useState(field.value ? field.value : "");
  const inputEl = useRef(null);
  
  const holidays = states.yearHolidays || []

  useEffect(() => {
    $(inputEl.current).daterangepicker(
      {
        singleDatePicker: true,
        showDropdowns: true,
        isInvalidDate: function (date) {
          if (restrictDates) {
            if (date.day() == 0 || date.day() == 6) {
              return true;
            }

            for (let i of holidays) {
              if (date.format('YYYY-MM-DD') == i) {
                return true
              }
            }
          }
          return false;
        },
        minYear: 1900,
        autoApply: true,
        locale: {
          format: defaultFormat,
          daysOfWeek: moment.weekdaysMin(),
          monthNames: moment.monthsShort(),
          firstDay: moment.localeData().firstDayOfWeek(),
        },
        ...props,
      },
      function (start, end) {
        var dateValue = start.format(defaultFormat);
        helpers.setValue(dateValue);
        setInputValue(dateValue);
      }
    );

    const maskInstance = new Cleave(inputEl.current, {
      date: true,
      datePattern: ["d", "m", "Y"],
    });

    return () => {
      maskInstance.destroy();
    };
  }, []);

  return (
    <Input
      innerRef={inputEl}
      name={name}
      className="form-control form-control-solid"
      onBlur={field.onBlur}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
    />
  );
};

export default Datepicker;
