import { Controller } from "@hotwired/stimulus"
import '@simonwep/pickr/dist/themes/nano.min.css'
import Pickr from "@simonwep/pickr";

export default class extends Controller {
  static targets = ["picker", "input"]

  initialize() {
    this.initPicker();
  }

  initPicker() {
    this.picker = Pickr.create({
      el: this.pickerTarget,
      theme: 'nano',
      default: this.inputTarget.value,
      defaultRepresentation: 'HEX',
      comparison: true,

      components: {
        preview: true,
        palette: true,
        hue: true,
        interaction: {
          input: true,
          cancel: true,
          save: true
        }
        
      },
      i18n: {
      'btn:save': 'Selecionar',
      'btn:cancel': 'Cancelar',
      }
    })

    this.picker.on('save', (color, _instance) => {
      this.inputTarget.value = color.toHEXA().toString();

      this.picker.hide();
    });
  }
}