import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, ModalBody, ModalFooter } from "reactstrap";
import { Formik, Form, Field } from "formik";

import { GlobalContext } from "../contexts/GlobalState";
import { Label, FormGroup, Number } from "./controls";
import incomesSchema from "../../schemas/incomes.schema";

const FormUpdateIncome = ({ objectState, onFormSubmit, toggleModal }) => {
  const { states, setters } = useContext(GlobalContext);

  const onSubmit = (values) => {
    onFormSubmit(values);
  };

  return (
    <Fragment>
      <Formik
        initialValues={objectState}
        validationSchema={incomesSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <ModalBody className="container p-10">
            <Row>
              <Col md={3}>
                <FormGroup name="month_rev">
                  <Label htmlFor="month_rev" required>
                    Renda Fixa Mensal
                  </Label>
                  <Number name="month_rev" id="month_rev" />
                  {/* <Input name="month_rev" id="month_rev" /> */}
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup name="month_rev_other">
                  <Label htmlFor="month_rev_other" required>
                    Outro tipo de renda
                  </Label>
                  <Number name="month_rev_other" id="month_rev_other" />
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup name="variable_rev">
                  <Label htmlFor="variable_rev" required>
                    Renda não mensal
                  </Label>
                  <Number name="variable_rev" id="variable_rev" />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={toggleModal}
            >
              Fechar
            </button>
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </ModalFooter>
        </Form>
      </Formik>
    </Fragment>
  );
};
export default FormUpdateIncome;
