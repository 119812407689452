import RemoteContent from "./remote_content";
import Rails from "@rails/ujs";

export default class extends RemoteContent {
  static targets = ["investmentClass", "investmentSubclass", "investmentKind"];
  static values = {
    contentData: {
      type: Object,
      default: {
        investments: [],
      },
    },
    successMessage: {
      type: String,
      default: "Investimento adicionado/atualizado com sucesso",
    },
  };

  connect() {
    this.loadContent();
  }

  updateContentDataValue() {
    var contentHtml = "";
    this.contentDataValue.investments.map((content, index) => {
      contentHtml += this.contentTemplate(content, index);
    });

    this.contentTarget.innerHTML = contentHtml;
  }

  contentTemplate(investment, index) {
    return `
      <tr>
      <td >
        <span
         data-bs-toggle="tooltip"
         data-bs-placement="top"
         title=${investment.bank_name}
        >
          ${investment.name}
        </span>
      </td>
      <td>${investment.total}</td>
      <td>${investment.pl}</td>
      <td>${investment.month_rate_return}</td>
      <td>${investment.year_rate}</td>
      <td>${investment.date}</td>
      <td>${investment.quote_name}</td>
    <tr >
      <td>
        <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 collapse" id=transaction_${index}>
          <thead>
            <tr class="fw-bold fs-6 text-gray-800">
              <th>Data</th>
              <th>Valor</th>
              <th>PU</th>
              <th>Criação</th>
            </tr>
          </thead>
          <tbody>
            ${this.transactionTemplate(investment.transactions)}
          </tbody>
        </table>

      </td>
    </tr>

      `;
  }

  transactionTemplate(transactions) {
    var htmlBuilder = "";

    transactions.map((transaction) => {
      htmlBuilder += `
            <tr>
              <td>${transaction.day}</td>
              <td>${transaction.total_with_mask}</td>
              <td>${transaction.pu}</td>
              <td>${transaction.created_at}</td>
            </tr>
          `;
    });

    return htmlBuilder;
  }
}
