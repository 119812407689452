import React from "react";
import SelectField from "react-select";

import { selectStyles } from "../forms/controls/styles";

const Select = (props) => {
  return (
    <SelectField
      styles={selectStyles()}
      {...props}
      placeholder="Selecione"
      noOptionsMessage={() => "Nenhum resultado encontrado"}
    />
  );
};

export default Select;
