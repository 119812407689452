import axios, { getResponseError } from "../services/axiosRequest";

import { successMessage } from "../utils/toast";

export const index = async (userId, setter, callback) => {
  await axios
    .get(`users/${userId}/user_investments/`)
    .then((result) => {
      setter(result.data);
      callback();
    })
    .catch(getResponseError);
};

export const associate = async (userId, setter, data, callback, setLoading) => {
  await axios
    .post(`/users/${userId}/user_investments`, data)
    .then(() => {
      index(userId, setter, callback);
      successMessage("Investimento associado com sucesso!");
      setLoading(false)
    })
    .catch(getResponseError);
};

export const create = async (userId, setter, data, callback, setLoading) => {
  await axios
    .post(`/investment_templates`, data)
    .then(() => {
      index(userId, setter, callback);
      successMessage("Investimento criado e associado com sucesso!");
      setLoading(false)
    })
    .catch(getResponseError);
};

export const destroy = async (
  userId,
  setInvestments,
  investmentId,
  callback
) => {
  await axios
    .delete(`/users/${userId}/user_investments/${investmentId}`)
    .then((res) => {
      index(userId, setInvestments, callback);
      successMessage("Investimento excluído com sucesso!");
    })
    .catch((err) => {
      getResponseError(err);
    });
};
