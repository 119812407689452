import React, { useState, useContext } from "react";
import * as S from "../../style/styles";

import { GlobalContext } from "../contexts/GlobalState";
// import createNewDocument

import { Loader } from "../elements/Loader";
import ModalForm from "../modals/ModalForm";
import TableRemoteContent from "../tables/TableRemoteContent";
import {
  createUserDocument,
  deleteUserDocument,
} from "../../services/userProfileRequests";

import { SwalButton } from "../buttons";
import DocumentForm from "../forms/DocumentForm";

const Document = ({ active, userId, isLoading }) => {
  const { states, setters } = useContext(GlobalContext);
  const { userDocuments } = states;
  const { setUserDocuments } = setters;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tabValue = 10;
  const isActive = active === tabValue;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const creatAction = (values) => {
    createUserDocument(userId, afterSubmitForm, values);
  };

  const destroyAction = (document) => {
    deleteUserDocument(userId, afterSubmitForm, document.id);
  };

  const afterSubmitForm = (data) => {
    setIsModalOpen(false);
    setUserDocuments(data);
  };

  return (
    <S.DisplayContainer isActive={isActive} isLoading={isLoading}>
      <Loader />

      <div className="content-display">
        <TableRemoteContent
          cardTitle="Documentos"
          isEditable={true}
          actionType='create'
          setIsModalOpen={setIsModalOpen}

        >
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead></thead>
            <tbody>
              {userDocuments.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{value.translated_document_type}</td>
                    <td className="text-end">
                      <a
                        href={value.attachment_url}
                        target="_blank"
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      >
                        <i className="bi bi-download fs-7"></i>
                      </a>
                      <SwalButton
                        className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                        onSuccess={() => destroyAction(value)}
                        swalProps={{
                          title: "Você deseja excluir o documento?",
                        }}
                      >
                        <i className="bi bi-trash fs-7"></i>
                      </SwalButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableRemoteContent>

        <ModalForm
          isOpen={isModalOpen}
          toggle={toggleModal}
          label="Upload de Documentos"
        >
          <DocumentForm
            onFormSubmit={creatAction}
            closeModal={() => toggleModal()}
          />
        </ModalForm>

      </div>


    </S.DisplayContainer>
  );
};

export default Document;
