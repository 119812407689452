import React, { useContext } from "react";
import * as S from "../../style/styles";
import { GlobalContext } from "../contexts/GlobalState";
import { Loader } from "../elements/Loader";
import TableRemoteContent from "../tables/TableRemoteContent";

const TypesOfInvestment = ({ active, userId, isLoading }) => {
  const { states, setters } = useContext(GlobalContext);
  const { userTypesOfInvestment } = states;
  const tabValue = 6;
  const isActive = active === tabValue;

  return (
    <S.DisplayContainer isActive={isActive} isLoading={isLoading}>
      <Loader />

      <div className="content-display">
        <TableRemoteContent
          cardTitle="Tipos de investimento"
          isEditable={false}

        >
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr>
                <th>Tipo</th>
                <th>R$</th>
                <th>%</th>
              </tr>
            </thead>
            <tbody>
              {userTypesOfInvestment.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.amount}</td>
                    <td>{item.percentage}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableRemoteContent>
      </div>


    </S.DisplayContainer>
  );
};

export default TypesOfInvestment;
