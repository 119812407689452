import React from 'react'
import { Label as BSLabel } from 'reactstrap'

const Label = ({ children, required, ...props }) => (
  <BSLabel {...props}>
    { required ? '* ' : '' }{ children }
  </BSLabel>
)

export default Label
