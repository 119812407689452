import { Controller } from "@hotwired/stimulus"
import $, { data } from 'jquery'

import { remoteSelect2 } from "../forms/select2"
import axios, { getResponseError } from '../utils/request'

export default class extends Controller {
  static targets = ["investmentClass", "investmentSubclass", "investmentKind"]
  static values = {
    investmentSubclasses: {
      type: Array, 
      default: []
    }
  }

  initialize () {
    this.loadSelect2Component()
  }
  
  updateSubclassList() {
    this.loadInvestmentSubclass()
  }

  loadInvestmentSubclass() {
    request
      .get(`investment_classes/${this.investmentClassTarget.value}/investment_subclasses/`)
      .then((result) => {
        this.investmentSubclassesValue = result.data
        this.reloadContent(
          $('#investment_template_investment_subclass_id'),
          result.data,
          this.investmentSubclassTarget.value
        )

        this.reloadContent(
          $('#investment_template_investment_kind_id'),
          [],
          this.investmentSubclassTarget.value
        )
      }).catch(error => {
        getResponseError(error)
      })
  }

  updateKindList(event){
    var investmentKinds = 
      this.investmentSubclassesValue
        .find(element => String(element.id) == event.currentTarget.value)
        .investment_kinds
    this.reloadContent(
      $('#investment_template_investment_kind_id'),
      investmentKinds,
      this.investmentKindTarget
    )
  }


  reloadContent(element, objects, target) {

    element.empty()
    element.append(new Option('Selecione', '', false))

    for(let object of objects) {
      element.append(new Option(object.name, object.id, false, String(object.id) === target))
    }

    element.trigger('change')
  }

  loadSelect2Component() {
    const options = $('#add-investments-modal').length ? { dropdownParent: $('#add-investments-modal')} : {}
    remoteSelect2(
      '.quantum-select-list',
      'services/quantum_investments',
      {investment_class_id: this.investmentClassTarget.value},
      this.processRemoteContent,
      options
    )  
  }

  processRemoteContent(data) {
    return (
      data.map((investment) => {
        return {
          id: investment.ctr,
          text: investment.name
        }
      })
    )
  }
}