import React from "react";
import { Button, CardFooter, Col, Row, Table } from "reactstrap";
import TableRemoteContent from "./TableRemoteContent";
import { IconButton, SwalButton } from "../buttons";
import { Loader } from "../elements/Loader";

export default ({ content, onClick, icon, label, isLoading }) => {
  return (
    <TableRemoteContent isEditable={false} cardTitle={label}>
      {isLoading ?
        <Loader />
        :
        <Table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
          <thead>
            <tr>
              <th>Usuário</th>
              <th>Ativo</th>
              <th>PL</th>
              <th>Saldo</th>
              <th>Mês</th>
              <th>% anterior na carteira</th>
              <th>% na carteira</th>
            </tr>
          </thead>
          <tbody>
            {content.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.user_name}</td>
                  <td
                    style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
                  >
                    {item.name}
                  </td>
                  <td>{item.pl}</td>
                  <td>{item.total}</td>
                  <td>{item.month_rate_return}</td>
                  <td>{item.percentage_in_wallet}</td>
                  <td>{item.preview_percentage_in_wallet}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot className="mb-5"></tfoot>
        </Table>
      }

      <CardFooter className="d-flex justify-content-end py-6 px-9">
        <SwalButton
          className="btn btn-primary"
          onSuccess={() => onClick()}
          swalProps={{
            title: "Você deseja enviar as recomendações",
          }}
        >
          Enviar
        </SwalButton>
      </CardFooter>
    </TableRemoteContent>
  );
};
