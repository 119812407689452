import React from "react";
import InputMaskField from "react-input-mask";
import { useField } from "formik";
import classNames from "classnames";

const PhoneInputMask = (props) => {
  const [field, meta, helpers] = useField(props);

  const phoneInputClass = classNames(
    `form-control ${props.className ? props.className : "form-control-solid"}`,
    {
      "border-danger": meta.error && meta.touched,
    }
  );

  const handlerChangeInput = (event) => {
    if (!meta.touched) {
      helpers.setTouched(true);
    }

    const value = event.target.value.replace(/[^0-9]+/g, "");
    helpers.setValue(value);
  };

  return (
    <InputMaskField
      className={phoneInputClass}
      {...props}
      mask={"(99) 99999-9999"}
      formatChars={{ 9: "[0-9]", "?": "[0-9 ]" }}
      maskChar={null}
      onChange={handlerChangeInput}
      value={field.value}
    />
  );
};

export default PhoneInputMask;
