import Rails from "@rails/ujs";
import Form from "../forms";

// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")
require("jquery");
require("@nathanvda/cocoon");

import "utils/swal";

new Form();

Rails.start();

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// Support component names relative to this directory:
import "controllers";
