import BankAccount from "./BankAccount";
import Document from "./Document";
import History from "./History";
import Income from "./Income";
import Investment from "./Investment";
import PersonalData from "./PersonalData";
import PerformanceHistory from "./PerformanceHistory";
import Liquidity from "./Liquidity";
import TypesOfInvestment from "./TypesOfInvestment";
import AllocationProfile from "./AllocationProfile";
import Issue from "./Issue";
import Extract from "./Extract";
import Payments from "./Payments";
import ClientHistory from "./ClientHistory";

export {
  BankAccount,
  Document,
  History,
  Income,
  Investment,
  PersonalData,
  Liquidity,
  TypesOfInvestment,
  AllocationProfile,
  Issue,
  Extract,
  PerformanceHistory,
  Payments,
  ClientHistory
};
