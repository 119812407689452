import React from "react";
import { useState } from "react";
import { Label } from "reactstrap";
import { FormGroup, Number, Input } from "../forms/controls";
import { Formik, Form } from "formik";
import Swal from "sweetalert2/dist/sweetalert2";
import axios from "axios";

const Steps = [
  {
    id: 1,
    name: "1",
    title: "Qual seu objetivo?",
    options: [
      "Melhorar o Investimento",
      "Comprar um carro",
      "Comprar um imovel",
      "Fazer uma viagem",
      "Me aposentar",
    ],
  },
  {
    id: 2,
    name: "2",

    title: "Qual sua renda mensal fixa?",
  },
  {
    id: 3,
    name: "3",

    title: "Outras rendas?",
  },
  {
    id: 4,
    name: "4",

    title: "Despesas fixas?",
  },
  {
    id: 5,
    name: "5",

    title: "Valores investidos em bancos e corretoras?",
  },
  {
    id: 6,
    name: "6",

    title: "Estado Civil",
    options: [
      "Solteiro(a)",
      "Casado(a)",
      "Divorciado(a)",
      "Separado(a) (judicial ou extrajudicialmente)",
      "Viúvo(a)",
    ],
  },
  {
    id: 7,
    name: "7",

    title: "Idade",
    options: [
      "0 a 29 anos",
      "30 a 39 anos",
      "40 a 49 anos",
      "50 a 60",
      "Acima de 60 anos",
    ],
  },
  {
    id: 8,
    name: "8",

    title: "Filhos",
    options: ["0 a 4 ", "Acima de 4"],
  },
  {
    id: 9,
    name: "9",

    title: "Qual opção melhor define o seu objetivo?",
    options: [
      "Preservação do capital e poder de compra",
      " Geração de Renda(implica retiradas frequentes)",
      "Aumento de Capital",
    ],
  },
  {
    id: 10,
    name: "10",

    title:
      "Qual percentual do patrimonio você pretende resgatar nos próximos 12 meses?",
    options: ["0% a 25%", "25% a 50%", "50% a 75%", "75% a 100%"],
  },
  {
    id: 11,
    name: "11",

    title: "Em Quanto tempo você espera resgatar 100% dos seus investimentos?",
    options: ["até 1 ano", "1 a 3 anos", "3 a 5 anos", "acima de 5 anos"],
  },
  {
    id: 12,
    name: "12",

    title:
      "O que você faria se após 6 meses, o investimento estivesse negativo?",
    options: [
      "Resgataria imediatamente",
      "Definiria um limite máximo de perda antes de resgatar",
      "Investiria mais recursos",
    ],
  },
];

export default function LeadsQuiz() {
  const [currentStep, setCurrentStep] = useState(0);
  function handleNextStep() {
    setCurrentStep((step) => step + 1);
  }
  function handleBackStep() {
    setCurrentStep((step) => step - 1);
  }
  const onSubmit = (values) => {
    const body = [];
    console.log(values);

    Object.keys(values).map((key) => {
      console.log(key);
      let value = {};

      value.question = key;
      console.log(values[key]);
      console.log(Steps[key - 1]?.options !== undefined);

      if (
        Steps[key - 1]?.options !== undefined &&
        Steps[key - 1].options.indexOf(values[key]) >= 0
      ) {
        console.log("index", Steps[key - 1].options.indexOf(values[key]));
        value.alternative = Steps[key - 1].options.indexOf(values[key]) + 1;
      } else {
        value.value = values[key];
      }
      body.push(value);
    });
    console.log(body);

    axios
      .patch("/payments", { client_questions_attributes: body })
      .then(() =>
        Swal.fire({
          title: "Resposta registrada!",
          icon: "success",
          showConfirmButton: true,
          customClass: {
            confirmButton: "btn btn-primary",
          },

          confirmButtonText: "Confirmar",
        }).then(() => window.location.reload())
      )
      .catch((error) => console.error(error));
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
      }}
    >
      <Form>
        <div className="content-display">
          <div className="card">
            <div className="card-body">
              <h1 className="mb-10">Cadastro</h1>
              <div className="separator my-4"></div>
              <h6 className="mb-2">Progresso</h6>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  aria-valuenow={(currentStep * 100) / (Steps.length - 1)}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{
                    width: `${Math.round(
                      (currentStep * 100) / (Steps.length - 1)
                    )}%`,
                  }}
                ></div>
              </div>
              <div className="d-flex p-12 justify-content-center  align-items-start ">
                <div className="d-flex p-12  rounded w-75 flex-column">
                  <h3 className="mb-10">
                    {currentStep + 1}-{Steps[currentStep].title}
                  </h3>
                  <div className="d-flex flex-column">
                    {Steps[currentStep].options ? (
                      Steps[currentStep].options.map((values) => {
                        return (
                          <FormGroup
                            name={Steps[currentStep].name}
                            key={values}
                          >
                            <Label
                              className="custom-control-label"
                              htmlFor={Steps[currentStep].name}
                            >
                              <Input
                                type="radio"
                                name={Steps[currentStep].name}
                                id={Steps[currentStep].name}
                                className="me-2"
                                value={values}
                              />
                              {values}
                            </Label>
                          </FormGroup>
                        );
                      })
                    ) : (
                      <FormGroup name={Steps[currentStep].name}>
                        <Label htmlFor={Steps[currentStep].name} required>
                          Valor:
                        </Label>
                        <Number
                          name={Steps[currentStep].name}
                          id={Steps[currentStep].name}
                        />
                      </FormGroup>
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex p-12 flex-stack">
                <div className="me-2">
                  <button type="button" className="btn btn-danger">
                    Cancelar
                  </button>
                </div>
                {currentStep < Steps.length - 1 ? (
                  <div
                    className={`d-flex ${
                      currentStep >= 1 ? "" : ""
                    } flex-stack`}
                  >
                    {currentStep >= 1 ? (
                      <button
                        type="button"
                        className="btn btn-secondary m-3"
                        onClick={handleBackStep}
                      >
                        <span className="indicator-label">Anterior</span>
                        <span className="indicator-progress">
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                    ) : null}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleNextStep}
                    >
                      <span className="indicator-label">Continuar</span>
                      <span className="indicator-progress">
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                ) : (
                  <div>
                    <button type="submit" className="btn btn-primary">
                      <span className="indicator-label">Salvar</span>
                      <span className="indicator-progress">
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
