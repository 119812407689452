import axios, { getResponseError } from "../services/axiosRequest";
import qs from "qs";

export const index = (setter, params, setIsLoading) => {
  axios
    .get(`investments/`, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    })
    .then((result) => {
      setter(result.data);
      setIsLoading && setIsLoading(false)
    })
    .catch((error) => {
      getResponseError(error);
      setIsLoading && setIsLoading(false)
    });
};
