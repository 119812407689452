import RemoteContent from './remote_content'


export default class extends RemoteContent {
  static targets = ['date']
  static values = { 
      contentData: {
        type: Object,
        default: {}
      }
    }

    updateContentDataValue() {
    var htmlContent = ''
    if(Object.keys(this.contentDataValue).length !== 0 ){
      htmlContent = this.contentTemplate()
    }
    
    this.contentTarget.innerHTML = htmlContent
  }

  changeDate(event) {
    
    this.urlValue = `${event.currentTarget.dataset.url}?date='${event.currentTarget.value}'`
    this.loadContent()
  }

  contentTemplate() {
      return `
      <tr>
        <td>Última movimentação</td>
        <td>${ this.contentDataValue.date }</td>
      </tr>
      <tr>
        <td>Patrimônio</td>
        <td>${ this.contentDataValue.total }</td>
      </tr>
      <tr>
        <td>Retorno no Mês</td>
        <td>${ this.contentDataValue.month_rate_return }</td>
      </tr>
      <tr>
        <td>Retorno no mês (%CDI)</td>
        <td>${ this.contentDataValue.cdi_rate }</td>
      </tr>
      <tr>
        <td>Retorno no mês (R$)</td>
        <td>${ this.contentDataValue.monetize_rate }</td>
      </tr>
      <tr>
        <td>Primeira Movimentação:</td>
        <td>${ this.contentDataValue.first_movement }</td>
      </tr>
      <tr>
        <td>Última atualização:</td>
        <td>${ this.contentDataValue.last_movement }</td>
      </tr>
     `
  }
}