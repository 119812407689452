import React, { useState, useRef, useEffect } from "react";
import { Input } from "reactstrap";
import $ from "jquery";
import moment from "moment";
import "daterangepicker";
import "moment/locale/pt-br";
import Cleave from "cleave.js";
import styled from "styled-components";

const InputDate = styled(Input)`
  .table-condensed thead tr:nth-child(2),
  .table-condensed tbody {
    display: none;
  }
`;

const defaultFormat = "DD/MM/YYYY";

const DatepickerMonth = ({ name, onValueChange, ...props }) => {
  const [inputValue, setInputValue] = useState(props.value);
  const inputEl = useRef(null);

  useEffect(() => {
    $(inputEl.current)
      .daterangepicker(
        {
          singleDatePicker: true,
          showDropdowns: true,
          minYear: 1900,
          autoApply: true,
          locale: {
            format: defaultFormat,
            daysOfWeek: moment.weekdaysMin(),
            monthNames: moment.monthsShort(),
            firstDay: moment.localeData().firstDayOfWeek(),
          },
          ...props,
        },
        function (start, end) {
          var dateValue = start.format(defaultFormat);
          setInputValue(dateValue);
          onValueChange(dateValue);
        }
      )
      .on("hide.daterangepicker", function (ev, picker) {
        $(".table-condensed tbody tr:nth-child(2) td").click();
      });

    const maskInstance = new Cleave(inputEl.current, {
      date: true,
      datePattern: ["d", "m", "Y"],
      // datePattern: ["m", "Y"],
    });

    return () => {
      maskInstance.destroy();
    };
  }, []);

  return (
    <InputDate
      innerRef={inputEl}
      name={name}
      className="form-control form-control-solid"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
    />
  );
};

export default DatepickerMonth;
