import axios, { getResponseError } from "../services/axiosRequest";

export const index = (setter) => {
  axios
    .get(`/quantum_investments`)
    .then((result) => {
      setter(
        result.data.map((element) => ({
          value: element.ctr,
          label: element.name,
        }))
      );
    })
    .catch(getResponseError);
};
