import { Controller } from "@hotwired/stimulus"

import axios, { getResponseError } from '../utils/request'
import { errorMessage } from "../utils/toast"

export default class extends Controller {
  static targets = ["content", "investmentClass", "fullRegistration", "requiredFields"]
  static values = {
    consolidatedPortfolio: {
      type: Array,
      default: []
    }
  }

  loadConsolidatedPortfolios(event) {
    if(event.currentTarget.value) {
      this.fullRegistrationTarget.classList.add("d-none")
      this.unsetRequiredFields()
      this.contentTarget.innerHTML = this.loadTemplate()
      this.getConsolidatedPortfolioData(event.currentTarget.value)
    } else {
      this.contentTarget.innerHTML = ''
      this.fullRegistrationTarget.classList.remove("d-none")
      this.setRequiredFields()
    }
  }

  getConsolidatedPortfolioData(value) {
    axios.get(`/consolidated_portfolios?ctr=${value}`)
    .then((result) => {
      this.consolidatedPortfolioValue = result.data
      this.contentTarget.innerHTML = this.consolidatedPortfolioTemplate()
    })
    .catch((result) => {
      this.contentTarget.innerHTML = ''
      this.setRequiredFields()

      getResponseError(result)
    })
  }

  setRequiredFields() {

    this.requiredFieldsTargets.map((element) => {
      element.setAttribute("required", "required");
    })
  }

  unsetRequiredFields() {
    this.requiredFieldsTargets.map((element) => {
      element.removeAttribute("required");
    })
  }

  loadTemplate(){
    return `
    <div">
      <div class="row text-center">
        <i class="fas fa-spinner fa-spin fs-3x"></i>
      </div>
    </div>`
  }

  consolidatedPortfolioTemplate() {
    return `
      <div class="col-md-12">
        <h2 class='mt-10'>Portifólio Consolidado </h2>
        <div class="separator my-4"></div>
        <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
          <tbody data-consolidated-profiles-target='content'>
            ${this.loadConsolidatedPortfolio()}
          </tbody>
        </table>
      </div>
    `
  }

  loadConsolidatedPortfolio() {
    var result = ''
    this.consolidatedPortfolioValue.map((element) => {
      result +=`
      <tr>
        <td>${element.name}</td>
        <td>${element.value}</td>
      </tr>
      `
    })

    return result
  }
}