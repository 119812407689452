export const selectStyles = (error, touched) => {
  return {
    control: (base) => ({
      ...base,
      minHeight: 40,
      fontWeight: 500,
      boxShadow: "none !important",
      border: `none !important`,
      backgroundColor: "#F5F8FA",
      padding: "0.25rem 1rem",
      borderRadius: "0.475rem",
      color: "#5e6278",
    }),
  };
};
