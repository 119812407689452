import React from "react";
import TableRemoteContent from "../tables/TableRemoteContent";
import { IconButton } from "../buttons";
import { Loader } from '../elements/Loader'

export default ({ content, onClick, icon, label, isLoading }) => {
  return (
    <TableRemoteContent isEditable={false} cardTitle={label}>
      {isLoading ?
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
        :
        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
          <thead>
            <tr>
              <th>Usuário</th>
              <th>Ativo</th>
              <th>PL</th>
              <th>Saldo</th>
              <th>Mês</th>
              <th>% na carteira</th>
              <th className="text-end">Ações</th>
            </tr>
          </thead>
          <tbody>
            {content.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.user_name}</td>
                  <td>{item.name}</td>
                  <td>{item.pl}</td>
                  <td>{item.total}</td>
                  <td>{item.month_rate_return}</td>
                  <td>{item.percentage_in_wallet}</td>
                  <td className="text-end">
                    <IconButton onClick={() => onClick(item)} icon={icon} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      }
    </TableRemoteContent>
  );
};
