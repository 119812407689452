import { useContext, useState } from "react";
import axios, { getResponseError } from "./axiosRequest.js";
import { successMessage } from "../utils/toast";

// GET ALL DATA
export const getUserData = (userId, set, route, setIsLoading) => {
  axios
    .get(`/users/${userId}${route}`)
    .then((res) => {
      set(res.data);
      setIsLoading && setIsLoading(false);
    })
    .catch((err) => {
      console.log(err);
      setIsLoading && setIsLoading(false);
    });
};

// CRUD PERSONAL DATA
export const editUserPersonalData = (userId, afterSubmitForm, data) => {
  axios
    .patch(`/users/${userId}`, data)
    .then((res) => {
      getUserData(userId, afterSubmitForm, "");
      successMessage("Dados do usuário atualizados com sucesso");
    })
    .catch((err) => {
      getResponseError(err);
    });
};

// CRUD BANK DATA
export const createUserBankAccount = (userId, afterSubmitForm, data) => {
  axios
    .post(`/users/${userId}/bank_accounts`, data)
    .then((res) => {
      getUserData(userId, afterSubmitForm, "/bank_accounts");
      successMessage("Dados bancários inseridos com sucesso!");
    })
    .catch((err) => {
      getResponseError(err);
    });
};

export const editUserBankAccount = (userId, afterSubmitForm, form) => {
  axios
    .patch(`/users/${userId}/bank_accounts/${form.id}`, form)
    .then((res) => {
      getUserData(userId, afterSubmitForm, "/bank_accounts");
      successMessage("Dados bancários editados com sucesso!");
    })
    .catch((err) => {
      getResponseError(err);
    });
};

export const getUserBankAccount = (userId, bankRegisterId, setData) => {
  axios
    .get(`/users/${userId}/bank_accounts/${bankRegisterId}`)
    .then((res) => {
      setData(res.data);
      console.log("bank register", res);
    })
    .catch((err) => {
      getResponseError(err);
    });
};

export const deleteUserBankAccount = (
  userId,
  setUserBankAccount,
  bankRegisterId
) => {
  axios
    .delete(`/users/${userId}/bank_accounts/${bankRegisterId}`)
    .then((res) => {
      getUserData(userId, setUserBankAccount, "/bank_accounts");
      successMessage("Dados bancários excluídos com sucesso!");
    })
    .catch((err) => {
      getResponseError(err);
    });
};

// CRUD ALLOCATION PROFILE

export const editUserAllocationProfile = (
  userId,
  form,
  setUserAllocationProfile
) => {
  console.log("chegando no edit form", form);
  // const newForm = {
  //     // user_profile: 1,
  //     user_allocation_profiles_attributes: form
  // }

  axios
    .patch(`/users/${userId}/allocation_profiles/${userId}`, form)
    .then((res) => {
      successMessage("Perfil de alocação editado com sucesso!");
      getUserData(userId, setUserAllocationProfile, "/allocation_profiles");
    })
    .catch((err) => {
      getResponseError(err);
    });
};

// INCOME

export const editUserIncome = (userId, afterSubmitForm, form) => {
  axios
    .patch(`/users/${userId}/incomes/${userId}`, form)
    .then((res) => {
      getUserData(userId, afterSubmitForm, "/incomes");
      successMessage("Renda editada com sucesso!");
    })
    .catch((err) => {
      getResponseError(err);
    });
};

// DOCUMENT

export const createUserDocument = (userId, afterSubmitForm, data) => {
  const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();
  formData.append("attachment", data.document_file);
  formData.append("document_type", data.document_type.value);

  axios
    .post(`/users/${userId}/documents`, formData, headers)
    .then((res) => {
      getUserData(userId, afterSubmitForm, "/documents");
      successMessage("Documento adicionado com sucesso!");
    })
    .catch((err) => {
      getResponseError(err);
    });
};

export const deleteUserDocument = (userId, setUserDocuments, documentId) => {
  axios
    .delete(`/users/${userId}/documents/${documentId}`)
    .then((res) => {
      getUserData(userId, setUserDocuments, "/documents");
      successMessage("Documento excluído com sucesso!");
    })
    .catch((err) => {
      err.response.data.errors.map((item) => {
        return errorMessage(item);
      });
    });
};

// EXTRACT

export const createUserExtract = (userId, afterSubmitForm, data) => {
  console.log(data);

  const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();
  formData.append("attachment", data.extract_file);

  axios
    .post(`/users/${userId}/statement_files`, formData, headers)
    .then((res) => {
      getUserData(userId, afterSubmitForm, "/statement_files");
      successMessage("Extrato adicionado com sucesso!");
    })
    .catch((err) => {
      getResponseError(err);
    });
};

export const deleteUserExtract = (userId, setUserDocuments, documentId) => {
  axios
    .delete(`/users/${userId}/statement_files/${documentId}`)
    .then((res) => {
      getUserData(userId, setUserDocuments, "/statement_files");
      successMessage("Extrato excluído com sucesso!");
    })
    .catch((err) => {
      err.response.data.errors.map((item) => {
        return errorMessage(item);
      });
    });
};

// OTHER DATA

export const getBanks = (set) => {
  axios
    .get(`/banks`)
    .then((res) => {
      set(
        res.data
        // res.data.map((element) => ({ label: element.name, value: element.id }))
      );
    })
    .catch((err) => console.log(err));
};

export const getPlans = (setPaymentPlans) => {
  axios
    .get(`/plans`)
    .then((res) => {
      setPaymentPlans(
        res.data.map((element) => ({ label: element.name, value: element.id }))
      );
    })
    .catch((err) => getResponseError(err));
};

export const getAdress = (set, zipcode) => {
  console.log(zipcode);
  axios
    .get(`/zipcode/${zipcode}`)
    .then((res) => {
      set("street", res.data.address);
      set("neighborhood", res.data.district);
      console.log(res);
      set(res.data);
    })
    .catch((err) => console.log(err));
};

export const getInvestmentTemplates = (setInvestmentTemplates) => {
  axios
    .get(`/investment_templates`)
    .then((res) => {
      setInvestmentTemplates(
        res.data.map((element) => ({ label: element.name, value: element.id }))
      );
    })
    .catch((err) => console.log(err));
};

export const editChargeMethot = (userId) => {
  axios
    .post(`/users/${userId}/history_payments`)
    .then((res) => {
      successMessage("A alteração foi feita com sucesso!");
    })
    .catch((err) => console.log(err));
}

export const createClientRegister = (userId, afterSubmitForm, form) => {
  axios
    .post(`/users/${userId}/user_histories`, form)
    .then((res) => {
      getUserData(userId, afterSubmitForm, "/user_histories")
      successMessage("Registro adicionado com sucesso!");
    })
    .catch((err) => console.log(err));
}