import RemoteContent from './remote_content'


export default class extends RemoteContent {
  static values = { 
      contentData: {
        type: Object,
        default: {}
      },
      successMessage: {
        type: String,
        default: 'Informações de renda atualizadas com sucesso'
      }
    }

    updateContentDataValue() {
    var htmlContent = ''
    
    if(Object.keys(this.contentDataValue).length !== 0 ){
      htmlContent = this.contentTemplate()
    }
    
    this.contentTarget.innerHTML = htmlContent
  }

  contentTemplate() {
      return `
      <tr>
        <td>Renda Fixa Mensal</td>
        <td>${ this.contentDataValue.month_rev }</td>
      </tr>
      <tr>
        <td>Outro tipo de renda</td>
        <td>${ this.contentDataValue.month_rev_other }</td>
      </tr>
      <tr>
        <td>Renda não mensal</td>
        <td>${ this.contentDataValue.variable_rev }</td>
      </tr>
     `
  }
}