import RemoteContent from './remote_content'


export default class extends RemoteContent {

  contentTemplate(question, index) {
      return `<tr key=${index}>
      <td>${ question.label }</td>
      <td>${ question.value }</td>
    </tr>`
  }
}