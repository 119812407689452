import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, ModalBody, ModalFooter } from "reactstrap";
import { Formik, Form, Field } from "formik";

import { GlobalContext } from "../contexts/GlobalState";
import {
  Select,
  // Input,
  Label,
  FormGroup,
  Datepicker,
} from "./controls";
import { Input } from "reactstrap";

const ExtractForm = ({ onFormSubmit, closeModal }) => {
  const { states, setters } = useContext(GlobalContext);

  const initialValues = {
    extract_file: "",
  };

  const onSubmit = (values) => {
    onFormSubmit(values);
  };

  return (
    <Fragment>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <ModalBody className="container p-10">
              <Row className=" my-3 d-flex justify-content-center">
                <Col md={8}>
                  <FormGroup name="extract_file">
                    <Label htmlFor="extract_file" required>
                      Extrato
                    </Label>
                    <Input
                      id="extract_file"
                      name="extract_file"
                      type="file"
                      onChange={(event) =>
                        setFieldValue("extract_file", event.target.files[0])
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Fechar
              </button>
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};
export default ExtractForm;
