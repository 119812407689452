import axios, { getResponseError } from "../services/axiosRequest";

export const index = (setter) => {
  axios
    .get(`/quotes`)
    .then((result) => {
      setter(
        result.data.map((element) => ({
          value: element.id,
          label: element.code,
        }))
      );
    })
    .catch(getResponseError);
};
