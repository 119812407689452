import React, { useEffect, useState, useContext } from "react";

import * as S from "../../style/styles";
import TableRemoteContent from "../tables/TableRemoteContent";
import { GlobalContext } from "../contexts/GlobalState";
import { Loader } from "../elements/Loader";

const Issue = ({ active, userId, isLoading }) => {
  const tabValue = 9;
  const isActive = active === tabValue;
  const { states, setters } = useContext(GlobalContext);
  const { userIssues } = states;

  return (
    <S.DisplayContainer isActive={isActive} isLoading={isLoading}>
      <Loader />

      <div className="content-display">
        <TableRemoteContent
          cardTitle="Questões"
          isEditable={false}

        >
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead></thead>
            <tbody>
              {userIssues.map((question, index) => {
                return (
                  <tr key={index}>
                    <td>{question.label}</td>
                    <td>{question.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableRemoteContent>
      </div>


    </S.DisplayContainer>
  );
};

export default Issue;
