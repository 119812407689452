import $ from 'jquery'
import moment from 'moment'
import 'daterangepicker'
import 'flatpickr'
import { Portuguese } from "flatpickr/dist/l10n/pt"
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import 'moment/locale/pt-br'

moment.locale('pt-BR')

const defaultOptions = {
  singleDatePicker: true,
  showDropdowns: true,
  minDate: '01/01/1900',
  autoApply: true,
  locale: {
    format: moment.localeData().longDateFormat('L'),
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek() }
}

export const datepicker = () => {
  $('.daterange-single').daterangepicker(defaultOptions)
}

export const birthDatepicker = () => {
  $('.birth-daterange-single').daterangepicker({
    ...defaultOptions,
    maxDate: moment()
  })
}

export const dateRangepicker = (selector = '.daterange') => {
  const format = 'DD/MM/YYYY'

  $(selector).daterangepicker({
    ...defaultOptions,
    singleDatePicker: false,
    timePicker: false,
    showDropdowns: false,
    autoUpdateInput: false
  })

  $(selector).on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format(format) + ' - ' + picker.endDate.format(format));
  });
  $(selector).on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
  });
}

export const flatpickr = (selector = '.datepicker') => {
  const format = 'd/m/Y'

  $(selector).flatpickr({
    dateFormat: format,
    locale: Portuguese,
  })

}

export const monthPicker = (selector = '.monthpicker') => {
  const format = 'd/m/Y'

  $(selector).flatpickr({
    dateFormat: format,
    locale: Portuguese,
    plugins: [
      monthSelectPlugin({
        shorthand: true, //defaults to false
        dateFormat: "m/y", //defaults to "F Y"
        altFormat: "F Y", //defaults to "F Y"
      })
  ]
    
  })

}


export default () => {
  datepicker()
  birthDatepicker()
  dateRangepicker()
  flatpickr()
  monthPicker()
}

