import React from "react";
import { Button } from "reactstrap";
import swal from "sweetalert2";

const defaultSwalProps = {
  title: "Você deseja realizar está ação?",
  icon: "warning",
  showCancelButton: true,
  cancelButtonText: "Cancelar",
  cancelButtonColor: "#C1C1C1",
  confirmButtonText: "Sim",
  confirmButtonColor: "#8CD4F5",
  reverseButtons: true,
};

const SwalButton = ({ children, swalProps, onSuccess, onCancel, ...props }) => {
  const handleButtonClick = () => {
    swal
      .fire({
        ...defaultSwalProps,
        ...swalProps,
      })
      .then((result) => {
        if (result.value) {
          if (onSuccess && typeof onSuccess === "function") {
            onSuccess();
          }
        } else {
          if (onCancel && typeof onCancel === "function") {
            onCancel();
          }
        }
      });
  };

  return (
    <div {...props} onClick={handleButtonClick}>
      {children}
    </div>
  );
};

export default SwalButton;
