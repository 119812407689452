import React from "react";
import { useField } from "formik";
import { Input as BSInput } from "reactstrap";
import classNames from "classnames";

const Input = ({ ...props }) => {
  const [field, meta] = useField(props);
  const hasErrors = meta.error && meta.touched;
  const inputClass = classNames(
    {
      "border-danger": hasErrors,
    },
    `form-control ${props.className ? props.className : "form-control-solid"}`
  );

  return <BSInput className={inputClass} {...field} {...props} />;
};

export default Input;
