import { Controller } from "@hotwired/stimulus";
import axios from "../services/axiosRequest";
import restartModals from "../utils/restartModals";
import jqueryContents from "../forms/jqueryContent";
import { select2, filterSelect2 } from "../forms/select2";
import { successMessage, errorMessage } from "../utils/toast";

export default class extends Controller {
  static targets = ["content", "modal"];

  static values = {
    contentData: {
      type: Array,
      default: [],
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    url: String,
    successMessage: {
      type: String,
      default: "",
    },
  };

  onFormSubmitSuccess(event) {
    let [data, status, xhr] = event.detail;

    restartModals();
    successMessage(this.successMessageValue);
    this.modalTarget.innerHTML = xhr.response;
    this.reloadContent();
  }

  reloadContent() {
    this.isOpenValue = false;
    this.loadContent();
  }

  onModalOpen(event) {
    let [data, status, xhr] = event.detail;
    this.modalTarget.innerHTML = xhr.response;
    select2({
      dropdownParent: $(event.target.dataset.bsTarget),
    });
    filterSelect2({
      dropdownParent: $(event.target.dataset.bsTarget),
    });

    jqueryContents(true);
  }

  onFormSubmitError(event) {
    let [data, status, xhr] = event.detail;
    errorMessage(data.errors[0]);
  }

  onResourceDestroy(event) {
    successMessage("Removido com sucesso");
    this.reloadContent();
  }

  submit() {
    Rails.fire(this.formTarget, "submit");
  }

  destroyResourceButton(url, controller) {
    return `
      <a href="${url}"
      class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
      data-confirm-swal="Você deseja excluir o registro?"
      data-action="ajax:success->${controller}#onResourceDestroy ajax:error->${controller}#onFormSubmitError"
      data-remote="true"
      data-swal-text="Você não poderá reverter esta ação!"
      rel="nofollow"
      data-method="delete"
      >
        <i class="fas fa-trash"></i>
      </a>
   `;
  }

  editResourceButton(url, modalTarget, controller) {
    return `
      <a href="${url}"
      class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
      data-remote="true"
      data-action="ajax:success->${controller}#onModalOpen ajax:error->${controller}#onFormSubmitError"
      rel="nofollow"
      data-bs-toggle="modal"
      data-bs-target="#${modalTarget}"
      >
        <i class="fas fa-pencil-alt"></i>
      </a>
   `;
  }

  newResourceButton(url, modalTarget, controller) {
    return `
      <a href="${url}"
      class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
      data-remote="true"
      data-action="ajax:success->${controller}#onModalOpen ajax:error->${controller}#onFormSubmitError"
      rel="nofollow"
      data-bs-toggle="modal"
      data-bs-target="#${modalTarget}"
      >
        <i class="bi bi-plus"></i>
      </a>
   `;
  }

  loadContent() {
    this.contentTarget.innerHTML = this.loadTemplate();
    axios
      .get(this.urlValue)
      .then((result) => {
        this.contentDataValue = result.data;
        this.updateContentDataValue();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateContentDataValue() {
    var contentHtml = "";
    this.contentDataValue.map((content, index) => {
      contentHtml += this.contentTemplate(content, index);
    });

    this.contentTarget.innerHTML = contentHtml;
  }

  contentTemplate(question, index) {
    return "";
  }

  loadTemplate() {
    return `
    <div">
      <div class="row text-center">
        <i class="fas fa-spinner fa-spin fs-3x"></i>
      </div>
    </div>`;
  }
}
