import React, { Fragment, useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

import { Select } from "../controls";
import SuggestionDetail from "../elements/SuggestionDetail";
import { RadioButton } from "../buttons";
import { index as getInvestments } from "../../api/allInvestment";

const suggestionTypes = [
  {
    id: "client",
    value: "client",
    label: "Por Cliente",
  },
  {
    id: "investment",
    value: "investment",
    label: "Por Ativo",
  },
  {
    id: "class_or_profile",
    value: "class_or_profile",
    label: "Por Classe ou Perfil",
  },
];

const Suggestion = ({
  users,
  investmentTemplates,
  investmentProfiles,
  userProfiles,
}) => {
  const [suggestionType, setSuggestionType] = useState("client");
  const [investments, setInvestments] = useState([]);
  const [activesSelected, setActivesSelected] = useState([]);
  const [investmentProfile, setInvestmentProfile] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResult, setIsLoadingResult] = useState(false);

  const addInvestment = (active) => {
    const index = investments.indexOf(active);
    investments.splice(index, 1);
    setInvestments(investments);
    setActivesSelected([...activesSelected, active]);
  };

  const removeInvestment = (active) => {
    const index = activesSelected.indexOf(active);
    activesSelected.splice(index, 1);
    setActivesSelected(activesSelected);
    setInvestments([...investments, active]);
  };

  const getClientInvestments = (client) => {
    setIsLoading(true);
    setActivesSelected([]);
    getInvestments(setInvestments, { user_id: client }, setIsLoading);
  };

  const getInvestmentByInvestmentTemplate = (investment) => {
    setIsLoading(true);
    setActivesSelected([]);
    getInvestments(
      setInvestments,
      { investment_template_id: investment },
      setIsLoading
    );
  };

  const getInvestmentByClassOrUserProfile = () => {
    setIsLoading(true);
    setActivesSelected([]);
    getInvestments(
      setInvestments,
      {
        user_profile: userProfile,
        investment_profile_id: investmentProfile,
        filter: "by_class_and_profile",
      },
      setIsLoading
    );
  };

  return (
    <Card className="mb-5 mb-xl-10">
      <CardBody className=" pt-9 pb-0">
        <Row>
          <div className="mb-10 d-flex">
            {suggestionTypes.map((element, index) => {
              return (
                <RadioButton
                  key={index}
                  id={element.id}
                  value={element.value}
                  name="suggestion_option"
                  label={element.label}
                  defaultChecked={suggestionType === element.value}
                  onClick={(e) => setSuggestionType(e.currentTarget.value)}
                />
              );
            })}
          </div>
        </Row>

        {suggestionType === "client" && (
          <SuggestionDetail
            users={users}
            removeInvestment={removeInvestment}
            addInvestment={addInvestment}
            investments={investments}
            setInvestments={setInvestments}
            activesSelected={activesSelected}
            setActivesSelected={setActivesSelected}
            investmentTemplates={investmentTemplates}
            isLoading={isLoading}
            isLoadingResult={isLoadingResult}
            setIsLoadingResult={setIsLoadingResult}
          >
            <Col md={6}>
              <label htmlFor="client">Selecione o nome do cliente:</label>
              <Select
                id="client"
                options={users}
                onChange={(e) => getClientInvestments(e.value)}
              />
            </Col>
          </SuggestionDetail>
        )}
        {suggestionType === "investment" && (
          <SuggestionDetail
            users={users}
            removeInvestment={removeInvestment}
            addInvestment={addInvestment}
            investments={investments}
            setInvestments={setInvestments}
            activesSelected={activesSelected}
            setActivesSelected={setActivesSelected}
            investmentTemplates={investmentTemplates}
            isLoading={isLoading}
            isLoadingResult={isLoadingResult}
            setIsLoadingResult={setIsLoadingResult}
          >
            <Col md={6}>
              <label htmlFor="client">Selecione o nome do ativo:</label>
              <Select
                id="client"
                options={investmentTemplates}
                onChange={(e) => getInvestmentByInvestmentTemplate(e.value)}
              />
            </Col>
          </SuggestionDetail>
        )}
        {suggestionType === "class_or_profile" && (
          <SuggestionDetail
            users={users}
            removeInvestment={removeInvestment}
            addInvestment={addInvestment}
            investments={investments}
            setInvestments={setInvestments}
            activesSelected={activesSelected}
            setActivesSelected={setActivesSelected}
            investmentTemplates={investmentTemplates}
            isLoading={isLoading}
            isLoadingResult={isLoadingResult}
            setIsLoadingResult={setIsLoadingResult}
          >
            <Fragment>
              <Col md={4}>
                <label htmlFor="investmentProfile">
                  Selecione o perfil do ativo:
                </label>
                <Select
                  id="investmentProfile"
                  options={investmentProfiles}
                  onChange={(e) => setInvestmentProfile(e.value)}
                />
              </Col>
              <Col md={4}>
                <label htmlFor="userProfile">
                  Selecione o perfil do usuário:
                </label>
                <Select
                  id="userProfile"
                  options={userProfiles}
                  onChange={(e) => setUserProfile(e.value)}
                />
              </Col>
              <Col md={2}>
                <button
                  type="button"
                  className="btn btn-icon btn-bg-light btn-active-color-primary mt-6"
                  onClick={getInvestmentByClassOrUserProfile}
                >
                  <i className="bi bi-filter"></i>
                </button>
              </Col>
            </Fragment>
          </SuggestionDetail>
        )}
      </CardBody>
    </Card>
  );
};

export default Suggestion;
