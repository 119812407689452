import styled from "styled-components";

export const DisplayContainer = styled.div`
  display: ${(props) => (props.isActive ? "block" : "none")};

  .loader {
    display: ${(props) => (props.isLoading ? "block" : "none")};
  }
  .content-display {
    display: ${(props) => (props.isLoading ? "none" : "block")};
  }
`;

export const Modal = styled.div`
  max-width: 700px !important;
`;

export const ScrollBar = styled.ul`
  overflow-x: scroll;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    margin-top: 5px;
    width: 15px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #8f8e8c;
  }
`;

export const SaveButton = styled.button`
  width: 86px;
  height: 43px;
`