import Cleave from "cleave.js";
import "cleave.js/dist/addons/cleave-phone.br";

import $ from "jquery";

export const defaultCPFDocumentMask = {
  numericOnly: true,
  blocks: [3, 3, 3, 3],
  delimiters: [".", ".", "-"],
};

export const defaultCNPJDocumentMask = {
  numericOnly: true,
  blocks: [2, 3, 3, 4, 2],
  delimiters: [".", ".", "/", "-"],
};

const masks = () => {
  documentMask();
  phoneMask();
  cellphoneMask();
  moneyMask();
  dateMask();
  shortDate();
  percentage();
  cvv();
  cardNumber();
};

const documentMask = () => {
  var activeFormat;

  $(".document").on("keyup", function () {
    if (!$(".document").length) {
      return false;
    }

    var mask;
    if (activeFormat != null) {
      activeFormat.destroy();
      activeFormat = null;
    }

    mask =
      $(this).val().length <= 14
        ? defaultCPFDocumentMask
        : defaultCNPJDocumentMask;
    activeFormat = new Cleave(".document", mask);
  });
};

const phoneMask = () => {
  if (!$(".phone").length) {
    return false;
  }

  new Cleave(".phone", {
    phone: true,
    phoneRegionCode: "BR",
  });
};

const cellphoneMask = () => {
  if (!$(".cellphone").length) {
    return false;
  }

  new Cleave(".cellphone", {
    phone: true,
    phoneRegionCode: "BR",
  });
};

export const moneyMask = () => {
  if (!$(".money").length) {
    return false;
  }

  $(".money")
    .toArray()
    .forEach(function (field) {
      new Cleave(field, {
        prefix: "R$ ",
        numeral: true,
        numeralDecimalScale: 2,
        numeralDecimalMark: ",",
        delimiter: ".",
        numeralThousandsGroupStyle: "thousand",
        signBeforePrefix: true,
        stripLeadingZeroes: false,
      });
    });
};

export const percentage = () => {
  if (!$(".percentage").length) {
    return false;
  }

  $(".percentage")
    .toArray()
    .forEach(function (field) {
      new Cleave(field, {
        numeral: true,
        numeralDecimalScale: 2,
        numeralDecimalMark: ",",
        delimiter: ".",
        numeralThousandsGroupStyle: "thousand",
        signBeforePrefix: true,
        stripLeadingZeroes: false,
      });
    });
};

const dateMask = () => {
  if (!$(".date-mask").length) {
    return false;
  }

  new Cleave(".date-mask", {
    date: true,
    datePattern: ["d", "m", "Y"],
  });
};

const shortDate = () => {
  if (!$(".short-date").length) {
    return false;
  }

  new Cleave(".short-date", {
    date: true,
    datePattern: ["m", "y"],
  });
};

const cvv = () => {
  if (!$(".cvv").length) {
    return false;
  }

  new Cleave(".cvv", {
    blocks: [3],
  });
};

const cardNumber = () => {
  if (!$(".card-number").length) {
    return false;
  }

  new Cleave(".card-number", {
    creditCard: true,
  });
};

export default masks;
