import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, ModalBody, ModalFooter } from "reactstrap";
import { Formik, Form } from "formik";

import { GlobalContext } from "../contexts/GlobalState";
import {
  Select,
  // Input,
  Label,
  FormGroup,
  Datepicker,
} from "./controls";
import { Input } from "reactstrap";

const DocumentForm = ({ onFormSubmit, closeModal }) => {
  const { states, setters } = useContext(GlobalContext);
  const { setUserDocuments } = setters;

  const initialValues = {
    document_type: "",
    document_file: "",
  };

  const documentType = [
    {
      value: "cpf",
      label: "CPF",
    },
    {
      value: "identification",
      label: "Identidade",
    },
    {
      value: "residence",
      label: "Comprovante de residência",
    },
  ];

  const onSubmit = (values) => {
    onFormSubmit({
      ...values,
      document_type: values.document_type,
    });
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        // validationSchema={createDocument}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <ModalBody className="container p-10">
              <Row className="my-3"className="d-flex justify-content-center">
                <Col md={8} >
                  <FormGroup name="document_type">
                    <Label htmlFor="document_type" required>
                      Tipo de Documento
                    </Label>
                    <Select
                      name="document_type"
                      id="document_type"
                      options={documentType}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="my-3" className="d-flex justify-content-center">
                <Col md={8} >
                  <FormGroup name="document_file">
                    <Label htmlFor="document_file" required>
                      Documento
                    </Label>
                    <Input
                      id="document_file"
                      name="document_file"
                      type="file"
                      onChange={(event) =>
                        setFieldValue("document_file", event.target.files[0])
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Fechar
              </button>
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Fragment >
  );
};
export default DocumentForm;
