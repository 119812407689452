import axios from "../services/axiosRequest";

export const getYearHolidays = (setValue) => {
  const today = new Date()
  const holidays = []
  axios
  .get(`https://brasilapi.com.br/api/feriados/v1/${today.getFullYear()}`)
  .then(res => {
    res.data?.map((item) => {
      holidays.push(item.date)
    })
    setValue(holidays)
  })
}