import React from "react";
import IconButton from "./IconButton";

export default ({ onClick }) => {
  return (
    <IconButton onClick={onClick}>
      <i className="bi bi-plus fs-3"></i>
    </IconButton>
  );
};
