import React from "react";
import NumberFormat from "react-number-format";
import { useField } from "formik";
import classNames from "classnames";

const Number = ({ valueKey, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasErrors = meta.error && meta.touched;
  const inputClass = classNames(
    "form-control",
    {
      "border-danger": hasErrors,
    },
    `form-control ${props.className ? props.className : "form-control-solid"}`
  );

  return (
    <NumberFormat
      prefix="R$ "
      className={inputClass}
      decimalSeparator=","
      thousandSeparator="."
      onValueChange={(value) => {
        if (!meta.touched) {
          helpers.setTouched(true);
        }
        helpers.setValue(value[valueKey || "floatValue"]);
      }}
      value={field.value}
      {...props}
    />
  );
};

export default Number;
