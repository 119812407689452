import RemoteContent from './remote_content'


export default class extends RemoteContent {

  contentTemplate(value, index) {
      return (
        `<tr key=${index}>
          <td>${ value.name }</td>
          <td class='text-end'>
          <a href=${value.attachment_url} class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
            <i class="fas fa-download"></i>
          </a>
          </td>
        </tr>`
      )
  }
}
