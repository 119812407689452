import React, { useState, useContext, useEffect } from "react";
import { GlobalState } from "../contexts/GlobalState";

import ProfileHeader from "../elements/ProfileHeader";
import {
  PersonalData,
  BankAccount,
  History,
  PerformanceHistory,
  Investment,
  Liquidity,
  TypesOfInvestment,
  AllocationProfile,
  Income,
  Issue,
  Document,
  Extract,
  Payments,
  ClientHistory,
} from "../tabs";

const UserProfile = ({ userId, isClient }) => {
  const [active, setActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <GlobalState isClient={isClient}>
      <ProfileHeader
        setActive={setActive}
        userId={userId}
        setIsLoading={setIsLoading}
      />
      <PersonalData active={active} userId={userId} isLoading={isLoading} />
      <BankAccount active={active} userId={userId} isLoading={isLoading} />
      <History
        active={active}
        userId={userId}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <PerformanceHistory
        active={active}
        userId={userId}
        isLoading={isLoading}
      />
      <Investment
        active={active}
        userId={userId}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <Liquidity active={active} userId={userId} isLoading={isLoading} />
      <TypesOfInvestment
        active={active}
        userId={userId}
        isLoading={isLoading}
      />
      <AllocationProfile
        active={active}
        userId={userId}
        isLoading={isLoading}
      />
      <Income active={active} userId={userId} isLoading={isLoading} />
      <Issue active={active} userId={userId} isLoading={isLoading} />
      <Document active={active} userId={userId} isLoading={isLoading} />
      <Extract active={active} userId={userId} isLoading={isLoading} />
      {isClient ? (
        ""
      ) : (
        <>
          <Payments active={active} userId={userId} isLoading={isLoading} />
          <ClientHistory
            active={active}
            userId={userId}
            isLoading={isLoading}
          />
        </>
      )}
    </GlobalState>
  );
};

export default UserProfile;
