import React, { Fragment } from "react";
import { ModalBody, ModalFooter } from "reactstrap";

const LiquidityDetail = ({ items, toggleModal }) => {
  return (
    <Fragment>
      <ModalBody className="container p-10">
        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
          <tbody>
            <tr>
              <td>Nome</td>
              <td className="text-end">Valor</td>
            </tr>
            {items &&
              items.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td className="text-end">{item.value}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={toggleModal}
        >
          Fechar
        </button>
      </ModalFooter>
    </Fragment>
  );
};

export default LiquidityDetail;
