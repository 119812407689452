import React, { useContext, useEffect, useState } from "react";
import * as S from "../../style/styles";
import { Formik, Form, Field } from "formik";
import { ModalBody, Row, Col } from "react-bootstrap";
import { GlobalContext } from "../contexts/GlobalState";

import { Select, Input, FormGroup, Label } from "../forms/controls";

const AllocationProfileForm = ({
  user,
  toggleModal,
  objectState,
  onFormSubmit,
}) => {
  const initialValues = {
    user_profile: {
      value: user.user_profile,
      label: user.user_profile_label,
    },
    user_allocation_profiles_attributes: objectState,
  };

  const onSubmit = (values) => {
    const params = { ...values, user_profile: values.user_profile.value };
    onFormSubmit(params);
  };

  const profiles = [
    { value: "agressive", label: "Capacidade para Agressivo" },
    { value: "bold", label: "Capacidade para Arrojado" },
    { value: "moderate", label: "Capacidade para Moderado" },
    { value: "conservative", label: "Conservador" },
  ];

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        <ModalBody className="container p-10">
          <Row>
            <Col md={12}>
              <FormGroup name="user_profile">
                <Label htmlFor="user_profile">
                  Perfil
                  <abbr title="required">*</abbr>
                </Label>
                <Select
                  name="user_profile"
                  id="user_profile"
                  options={profiles}
                />
              </FormGroup>
            </Col>
          </Row>
          <h2 className="my-10">Gerente</h2>
          <div className="separator my-4"></div>
          <Row>
            <table>
              <thead>
                <tr>
                  <td></td>
                  <td>% MIN</td>
                  <td>% MAX</td>
                </tr>
              </thead>

              <tbody>
                {objectState.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {item.investment_profile_name}
                        <input
                          name={`user_allocation_profiles_attributes[${index}][id]`}
                          type="hidden"
                          value
                        />
                      </td>
                      <td>
                        <FormGroup name="bank">
                          <Input
                            name={`user_allocation_profiles_attributes[${index}]min`}
                            id="min"
                          />
                        </FormGroup>
                      </td>
                      <td>
                        <FormGroup name="max">
                          <Input
                            name={`user_allocation_profiles_attributes[${index}]max`}
                            id="max"
                          />
                        </FormGroup>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Row>
        </ModalBody>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={toggleModal}
          >
            Fechar
          </button>
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default AllocationProfileForm;
