import React from "react";

export default ({ onClick, icon }) => {
  return (
    <button
      type="button"
      className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
      onClick={onClick}
    >
      <i className={`bi bi-${icon} fs-7`}></i>
    </button>
  );
};
