import toastr from 'toastr';
const defaultSettings = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,

}

const errorMessage = (text) => {
  toastr.error(text);
}

const successMessage = (text) => {
  
  toastr.success(text);
}

export {
  errorMessage,
  successMessage
}