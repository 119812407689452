import React, { Fragment, useState } from "react";
import { Row } from "reactstrap";

import Suggestion from "../tables/Suggestion";
import SuggestionResult from "./SuggestionResult";

export default ({
  removeInvestment,
  addInvestment,
  investments,
  children,
  activesSelected,
  investmentTemplates,
  isLoading,
  isLoadingResult,
  setIsLoadingResult
}) => {
  return (
    <Fragment>
      <Row>{children}</Row>

      <Row>
        <Suggestion
          label="Carteira do cliente"
          content={investments}
          onClick={addInvestment}
          icon="plus"
          isLoading={isLoading}
        />
      </Row>

      <Row>
        <Suggestion
          label="De"
          content={activesSelected}
          onClick={removeInvestment}
          icon="trash"
          // isLoading={isLoading}
        />
      </Row>

      <SuggestionResult
        activesSelected={activesSelected}
        investmentTemplates={investmentTemplates}
        isLoading={isLoadingResult}
        setIsLoading={setIsLoadingResult}
      />
    </Fragment>
  );
};
