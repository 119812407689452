import * as Yup from "yup";
import "./locale";

export default () => (
  Yup.object().shape({
    agency: Yup.number().required(),
    account: Yup.number().required(),
    bank: Yup.mixed(),
    name: Yup.string(),
    phone: Yup.string(),
    email: Yup.string().email()
  }))

