
import $ from 'jquery'

export default () => {

  if($('.close-modal')) {
    for(let element of $('.close-modal')) {
      element.click()
    }
  }

  $('.modal-backdrop').remove()
  $('body').removeClass('modal-open')

}
