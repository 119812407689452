import { Controller } from "@hotwired/stimulus";
import { CustomApexCharts } from "../forms/charts";
import { dashboardData, financeData, assetsData } from "../resources/home/data";

export default class extends Controller {
  static targets = ["card", "title", "description", "button"];
  static values = {
    chartOrder: {
      type: Array,
      default: [
        {
          chart: (element) => dashboardData(element, CustomApexCharts),
          title: "Dashboard",
          description: "Indicadores",
          position: "[0, 1, 2]",
        },
        {
          chart: (element) => assetsData(element, CustomApexCharts),
          title: "Patrimônio",
          description: "Valor total - cobrança sobre carteira/mês",
          position: "[1, 2, 0]",
        },
        {
          chart: (element) => financeData(element, CustomApexCharts),
          title: "Financeiro",
          description: "Valor total - mensalidade/mês",
          position: "[2, 0, 1]",
        },
      ],
    },
    index: 0,
  };

  initialize() {
    this.initializeCharts();
  }

  initializeCharts() {
    this.chartOrderValue.map((element, index) => {
      element.chart(this.cardTargets[index].querySelectorAll(".home-chart")[0]);
    });
  }

  reorderCharts(event) {
    var self = this;

    // Converte  ordem em JSON
    var chartOrders = JSON.parse(event.currentTarget.dataset.chart);

    // Pecorre todos os cards que tenham gráfico e os atualiza de acordo com a ordem selecionada
    this.cardTargets.map((element, index) => {
      // Encontra a div onde o gráfico está renderizado
      var cardDiv = element.querySelectorAll(".home-chart")[0];

      // Apaga o gráfico renderizado
      cardDiv.innerHTML = "";

      // Instancia um novo gráfico de acordo com a ordem de gráficos
      self.chartOrderValue[chartOrders[index]].chart(cardDiv);

      //Troca o título do card
      self.titleTargets[index].textContent =
        self.chartOrderValue[chartOrders[index]].title;
      //Troca o subtítulo do card
      self.descriptionTargets[index].textContent =
        self.chartOrderValue[chartOrders[index]].description;
      //Troca o data attribute para configurar a ordem dos cards
      self.buttonTargets[index].setAttribute(
        "data-chart",
        self.chartOrderValue[chartOrders[index]].position
      );
    });
  }
}
