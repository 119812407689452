
import Quill from 'quill';
import $ from 'jquery'


const quillOption = {
  theme: 'snow',
  modules: {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'font': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['code-block'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
      [{ 'align': [] }],

      ['clean']
    ]
  },
}

const quillInit = () => {
  if (!$('#container-text').length) {
    return false
  }

  const quill = new Quill('#container-text', quillOption)

  quill.on('text-change', function (delta) {
    $('#container-text-hidden').val(quill.root.innerHTML)
  });

}

export default () => {
  quillInit()
}
