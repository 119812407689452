import React from "react";

export default ({ consolidateWallet }) => {
  return (
    <div className="col-md-12">
      <h2 className="mt-10">Portifólio Consolidado </h2>
      <div className="separator my-4"></div>
      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
        <tbody data-consolidated-profiles-target="content">
          {consolidateWallet.map((element, index) => {
            return (
              <tr key={index}>
                <td>{element.name}</td>
                <td>{element.value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
