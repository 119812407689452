import React, { useState, useEffect, useContext, useDebugValue } from "react";
import * as S from "../../style/styles";

import InvestmentTransaction from "../elements/InvestmentTransaction";
import ModalForm from "../modals/ModalForm";
import InvestmentForm from "../forms/InvestmentForm";

import { GlobalContext } from "../contexts/GlobalState";
import { SwalButton } from "../buttons";
import { Loader } from "../elements/Loader";
import { getUserData } from "../../services/userProfileRequests";
import { associate, destroy, create } from "../../api/userInvestment";
import { index } from "../../api/investmentClasses";
import { index as getQuantumInvestments } from "../../api/quantumInvestments";
import { index as getInvestmentProfile } from "../../api/investmentProfiles";
import { index as getQuotes } from "../../api/quotes";

const Investment = ({ active, userId, isLoading, setIsLoading }) => {
  const { states, setters } = useContext(GlobalContext);
  const { userInvestments, isClient } = states;
  const {
    setUserInvestments,
    setUserHistory,
    setUserPerformanceHistory,
    setUserLiquidity,
    setUserTypesOfInvestment,
    setUserAllocationProfile,
    setUserPersonalData,
    setIsLoadingHistory,
  } = setters;

  const tabValue = 4;
  const isActive = active === tabValue;
  const [investment, setInvestment] = useState({});
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewInvestment, setIsNewInvestment] = useState(false);
  const [investmentClasses, setInvestmentClasses] = useState([]);
  const [quantumInvestments, setQuantumInvestments] = useState([]);
  const [investmentProfile, setInvestmentProfile] = useState([]);
  const [quotes, setQuotes] = useState([]);

  useEffect(() => {
    index(setInvestmentClasses);
    getQuantumInvestments(setQuantumInvestments);
    getInvestmentProfile(setInvestmentProfile);
    getQuotes(setQuotes);
  }, []);

  const updateRelatedData = () => {
    setIsLoading(true);
    setIsLoadingHistory(true);
    getUserData(userId, setUserPersonalData, "");
    getUserData(userId, setUserHistory, "/histories", setIsLoadingHistory);
    getUserData(userId, setUserPerformanceHistory, "/performance_histories");
    getUserData(userId, setUserLiquidity, "/liquidities");
    getUserData(userId, setUserInvestments, "/user_investments", setIsLoading);
    getUserData(userId, setUserTypesOfInvestment, "/investment_kinds");
    getUserData(userId, setUserAllocationProfile, "/allocation_profiles");
  };
  // chamar essa função toda vez que um dado de investimentos for alterado

  const afterSubmitForm = (data) => {
    setUserInvestments(data);
    setIsModalOpen(false);
  };

  const destroyAction = async (investment) => {
    await destroy(userId, afterSubmitForm, investment.id, updateRelatedData);
  };

  const toggleDetailModal = () => {
    setIsDetailModalOpen(!isDetailModalOpen);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const openInvestmentTransactions = (investment) => {
    setInvestment(investment);
    toggleDetailModal();
  };

  const openInvestmentForm = (newInvestment) => {
    setIsNewInvestment(newInvestment);
    toggleModal();
  };

  const creatAction = (values, setLoading) => {
    const createFunction = isNewInvestment ? create : associate;
    createFunction(
      userId,
      afterSubmitForm,
      values,
      updateRelatedData,
      setLoading
    );
  };

  return (
    <S.DisplayContainer isActive={isActive} isLoading={isLoading}>
      <Loader />

      <div className="content-display">
        <div className="card mb-5 mb-xl-10">
          <div className="card-header cursor-pointer">
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Investimentos</h3>
            </div>

            <div className="card-toolbar">
              {isClient ? (
                ""
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                    id="dropdownMenu2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-plus fs-3"></i>
                  </button>
                  <div
                    aria-labelledby="dropdownMenu2"
                    className="dropdown-menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3"
                  >
                    <div className="menu-item px-3">
                      <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                        Novo ativo
                      </div>
                    </div>

                    <div className="menu-item px-3">
                      <a
                        onClick={() => openInvestmentForm(false)}
                        className="menu-link px-3"
                      >
                        Associar um existente
                      </a>
                    </div>

                    <div className="menu-item px-3">
                      <a
                        onClick={() => openInvestmentForm(true)}
                        className="menu-link px-3"
                      >
                        Criar e associar
                      </a>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="card-body p-9 collapse show" id="investments">
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Banco</th>
                  <th>Saldo</th>
                  <th>PL</th>
                  <th>Mês</th>
                  <th>12 Meses</th>
                  <th>Data</th>
                  <th>Indexador</th>
                  <th className="text-end">Ações</th>
                </tr>
              </thead>
              <tbody>
                {userInvestments &&
                  userInvestments.investments &&
                  userInvestments.investments.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.bank_name}</td>
                        <td>{item.total}</td>
                        <td>{item.pl}</td>
                        <td>{item.month_rate_return}</td>
                        <td>{item.year_rate}</td>
                        <td>{item.date}</td>
                        <td>{item.quote_name}</td>
                        <td className="text-end">
                          <div className="me-0">
                            <div className="card-toolbar">
                              <button
                                type="button"
                                className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                                id="dropdownMenu2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="bi bi-three-dots fs-3"></i>
                              </button>
                              <div
                                aria-labelledby="dropdownMenu2"
                                className="dropdown-menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3"
                              >
                                <div className="menu-item px-3">
                                  <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                                    Ações
                                  </div>
                                </div>
                                <div className="menu-item px-3">
                                  <a
                                    onClick={() =>
                                      openInvestmentTransactions(item)
                                    }
                                    className="menu-link px-3"
                                  >
                                    Movimentações
                                  </a>
                                </div>

                                {isClient ? (
                                  ""
                                ) : (
                                  <>
                                    <div className="menu-item px-3">
                                      <a
                                        onClick={() => openInvestmentForm(true)}
                                        className="menu-link px-3"
                                      >
                                        Editar
                                      </a>
                                    </div>

                                    <div className="menu-item px-3">
                                      <SwalButton
                                        className="menu-link px-3"
                                        onSuccess={() => destroyAction(item)}
                                        swalProps={{
                                          title: "Você deseja excluir o ativo?",
                                        }}
                                      >
                                        Excluir
                                      </SwalButton>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ModalForm
        isOpen={isDetailModalOpen}
        toggle={toggleDetailModal}
        label="Transações"
      >
        <InvestmentTransaction
          userId={userId}
          investment={investment}
          closeModal={() => toggleDetailModal()}
          updateData={updateRelatedData}
        />
      </ModalForm>
      <ModalForm isOpen={isModalOpen} toggle={toggleModal} label="Investimento">
        <InvestmentForm
          investmentClasses={investmentClasses}
          isNewInvestment={isNewInvestment}
          quantumInvestments={quantumInvestments}
          investmentProfile={investmentProfile}
          toggleModal={toggleModal}
          onFormSubmit={creatAction}
          quotes={quotes}
          userId={userId}
        />
      </ModalForm>
    </S.DisplayContainer>
  );
};

export default Investment;
