import React, { useEffect, useState, useContext, Fragment } from "react";
import * as S from "../../style/styles";

import TableRemoteContent from "../tables/TableRemoteContent";
import { Loader } from "../elements/Loader";
import AllocationProfileForm from "../forms/AllocationProfileForm";
import { GlobalContext } from "../contexts/GlobalState";

import ModalForm from "../modals/ModalForm";
import { editUserAllocationProfile } from "../../services/userProfileRequests";

const AllocationProfile = ({ active, userId, isLoading }) => {
  const value = 7;
  const isActive = active === value;
  const { states, setters } = useContext(GlobalContext);
  const { userAllocationProfile, userPersonalData, isClient } = states;
  const { setUserAllocationProfile } = setters;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onFormSubmit = (values) => {
    editUserAllocationProfile(userId, values, afterSubmitForm);
  };

  const afterSubmitForm = (data) => {
    setIsModalOpen(false);
    setUserAllocationProfile(data);
  };

  return (
    <S.DisplayContainer isActive={isActive} isLoading={isLoading}>
      <Loader />
      <div className="content-display">
        <TableRemoteContent
          cardTitle="Perfil de Alocação"
          isEditable={!isClient}
          actionType="edit"
          className="content-display"
          setIsModalOpen={setIsModalOpen}
        >
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bold fs-6 text-gray-800">
                <th>Perfil</th>
                <th>Mínimo</th>
                <th>Máximo</th>
                <th>Atual</th>
              </tr>
            </thead>
            <tbody>
              {userAllocationProfile?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.investment_profile_name}</td>
                    <td>{item.min}</td>
                    <td>{item.max}</td>
                    <td>{item.current}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableRemoteContent>

        <ModalForm
          isOpen={isModalOpen}
          toggle={toggleModal}
          label="Perfil de alocação"
        >
          <AllocationProfileForm
            objectState={userAllocationProfile}
            user={userPersonalData}
            toggleModal={toggleModal}
            onFormSubmit={onFormSubmit}
          />
        </ModalForm>
      </div>
    </S.DisplayContainer>
  );
};

export default AllocationProfile;
