import RemoteContent from './remote_content'


export default class extends RemoteContent {
  static values = {
    successMessage: {
      type: String,
      default: 'Perfil de Alocação atualizado com sucesso'
    }
  }

  contentTemplate(allocationProfile, index) {
      return `
        <tr key=${index}>
          <td>${ allocationProfile.investment_profile_name }</td>
          <td>${ allocationProfile.min }</td>
          <td>${ allocationProfile.max }</td>
          <td>${ allocationProfile.current }</td>
        </tr>
      `
  }
}