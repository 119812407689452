import React, { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../contexts/GlobalState";
import { ScrollBar } from "../../style/styles";
import {
  getBanks,
  getInvestments,
  getPlans,
  getUserData,
  getInvestmentTemplates,
} from "../../services/userProfileRequests";
import { Loader } from "./Loader";
import { getYearHolidays } from "../../api/yearHollidays";

const ProfileHeader = ({ setActive, userId, setIsLoading }) => {
  const { states, setters } = useContext(GlobalContext);
  const {
    setUserPersonalData,
    setUserBankAccount,
    setUserHistory,
    setUserPerformanceHistory,
    setUserInvestments,
    setUserLiquidity,
    setUserTypesOfInvestment,
    setUserAllocationProfile,
    setUserIncome,
    setUserIssues,
    setUserDocuments,
    setUserExtracts,
    setBanks,
    setPaymentPlans,
    setInvestmentTemplates,
    setHistoryPayments,
    setClientHistory,
    setYearHolidays,
    setIsLoadingHistory,
    setIsClient,
  } = setters;

  const {
    userPersonalData,
    userBankAccount,
    userHistory,
    userPerformanceHistory,
    userInvestments,
    userLiquidity,
    userTypesOfInvestment,
    userAllocationProfile,
    userIncome,
    userIssues,
    userDocuments,
    userExtracts,
    banks,
    paymentPlans,
    historyPayments,
    clientHistory,
    isLoadingHistory,
    isClient,
  } = states;

  useEffect(() => {
    getYearHolidays(setYearHolidays);
    setIsLoading(true);
    setIsLoadingHistory(true);
    getUserData(userId, setUserPersonalData, "", setIsLoading);
    getUserData(userId, setUserHistory, "/histories", setIsLoadingHistory);
    getBanks(setBanks);
  }, []);

  const handleOnClick = (id) => {
    setIsLoading(true);
    var possibleIds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    if (!isClient) {
      possibleIds = possibleIds.concat([12, 13]);
    }

    setActive(id);
    possibleIds.forEach((item) => {
      var element = document.getElementById("btn" + item);
      element.classList.remove("active");
      element.classList.add("false");
    });
    var element = document.getElementById("btn" + id);
    element.classList.remove("false");
    element.classList.add("active");

    getInvestmentTemplates(setInvestmentTemplates);

    switch (id) {
      case 0:
        userPersonalData
          ? setIsLoading(false)
          : getUserData(userId, setUserPersonalData, "", setIsLoading);
        break;
      case 1:
        userBankAccount?.length
          ? setIsLoading(false)
          : getUserData(
              userId,
              setUserBankAccount,
              "/bank_accounts",
              setIsLoading
            );
        break;
      case 2:
        userHistory
          ? setIsLoading(false)
          : getUserData(userId, setUserHistory, "/histories", setIsLoading);
        break;
      case 3:
        getUserData(
          userId,
          setUserPerformanceHistory,
          "/performance_histories",
          setIsLoading
        );
        break;
      case 4:
        userInvestments?.investments.length
          ? setIsLoading(false)
          : getUserData(
              userId,
              setUserInvestments,
              "/user_investments",
              setIsLoading
            );
        break;
      case 5:
        userLiquidity?.investments?.length
          ? setIsLoading(false)
          : getUserData(userId, setUserLiquidity, "/liquidities", setIsLoading);
        break;
      case 6:
        userTypesOfInvestment?.length
          ? setIsLoading(false)
          : getUserData(
              userId,
              setUserTypesOfInvestment,
              "/investment_kinds",
              setIsLoading
            );
        break;
      case 7:
        userAllocationProfile?.length
          ? setIsLoading(false)
          : getUserData(
              userId,
              setUserAllocationProfile,
              "/allocation_profiles",
              setIsLoading
            );
        break;
      case 8:
        userIncome
          ? setIsLoading(false)
          : getUserData(userId, setUserIncome, "/incomes", setIsLoading);
        break;
      case 9:
        userIssues?.length
          ? setIsLoading(false)
          : getUserData(userId, setUserIssues, "/user_questions", setIsLoading);
        break;
      case 10:
        userDocuments?.length
          ? setIsLoading(false)
          : getUserData(userId, setUserDocuments, "/documents", setIsLoading);
        break;
      case 11:
        userExtracts?.length
          ? setIsLoading(false)
          : getUserData(
              userId,
              setUserExtracts,
              "/statement_files",
              setIsLoading
            );
        break;
      case 12:
        historyPayments?.length
          ? setIsLoading(false)
          : getUserData(
              userId,
              setHistoryPayments,
              "/history_payments",
              setIsLoading
            );
        break;
      case 13:
        clientHistory?.length
          ? setIsLoading(false)
          : getUserData(
              userId,
              setClientHistory,
              "/user_histories",
              setIsLoading
            );
        break;
      default:
        break;
    }
  };

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-body pt-9 pb-0">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div className="me-7 mb-4">
            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              {userPersonalData.picture ? (
                <img src={userPersonalData.picture} alt="Avatar do usuário" />
              ) : (
                <span className="symbol-label bg-light-#{color} text-#{color} fw-bolder">
                  {userPersonalData.name
                    ? userPersonalData.name.substring(0, 0)
                    : ""}
                </span>
              )}
              <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-text-nowrap h-20px w-20px"></div>
            </div>
          </div>

          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                  >
                    {userPersonalData.name}
                  </a>
                  <a href="#"></a>
                </div>

                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                  <a
                    href="#"
                    className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                  >
                    {userPersonalData.plan_name}
                  </a>
                </div>
              </div>

              <div className="d-flex my-4"></div>
            </div>

            <div className="d-flex flex-wrap flex-stack">
              <div className="d-flex flex-column flex-grow-1 pe-8">
                <div className="d-flex flex-wrap">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      {isLoadingHistory ? (
                        <Loader />
                      ) : (
                        <div className="fs-2 fw-bolder">
                          {userPersonalData.total}
                        </div>
                      )}
                    </div>

                    <div className="fw-bold fs-6 text-gray-400">Patrimônio</div>
                  </div>

                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">
                        {userPersonalData.billing_day
                          ? userPersonalData.billing_day
                          : "10"}
                      </div>
                    </div>

                    <div className="fw-bold fs-6 text-gray-400">
                      Dia de Vencimento
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ScrollBar className="d-flex h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mb-3">
            <li className="nav-item" role="button">
              <span
                id="btn0"
                onClick={() => handleOnClick(0)}
                className="nav-link text-active-primary me-6 text-nowrap active"
              >
                Dados Pessoais
              </span>
            </li>
            <li className="nav-item" role="button">
              <span
                id="btn1"
                onClick={() => handleOnClick(1)}
                className="nav-link text-active-primary me-6 text-nowrap"
              >
                Dados Bancários
              </span>
            </li>
            <li className="nav-item" role="button">
              <span
                id="btn2"
                onClick={() => handleOnClick(2)}
                className="nav-link text-active-primary me-6 text-nowrap"
              >
                Histórico
              </span>
            </li>
            <li className="nav-item" role="button">
              <span
                id="btn3"
                onClick={() => handleOnClick(3)}
                className="nav-link text-active-primary me-6 text-nowrap"
              >
                Histórico de Perfomance
              </span>
            </li>
            <li className="nav-item" role="button">
              <span
                id="btn4"
                onClick={() => handleOnClick(4)}
                className="nav-link text-active-primary me-6 text-nowrap"
              >
                Investimentos
              </span>
            </li>
            <li className="nav-item" role="button">
              <span
                id="btn5"
                onClick={() => handleOnClick(5)}
                className="nav-link text-active-primary me-6 text-nowrap"
              >
                Liquidez
              </span>
            </li>
            <li className="nav-item" role="button">
              <span
                id="btn6"
                onClick={() => handleOnClick(6)}
                className="nav-link text-active-primary me-6 text-nowrap"
              >
                Tipos de Investimento
              </span>
            </li>
            <li className="nav-item" role="button">
              <span
                id="btn7"
                className="nav-link text-active-primary me-6 text-nowrap"
                onClick={() => handleOnClick(7)}
              >
                Perfil de Alocação
              </span>
            </li>
            <li className="nav-item" role="button">
              <span
                id="btn8"
                className="nav-link text-active-primary me-6 text-nowrap"
                onClick={() => handleOnClick(8)}
              >
                Renda
              </span>
            </li>
            <li className="nav-item" role="button">
              <span
                id="btn9"
                className="nav-link text-active-primary me-6 text-nowrap"
                onClick={() => handleOnClick(9)}
              >
                Questões
              </span>
            </li>
            <li className="nav-item" role="button">
              <span
                id="btn10"
                className="nav-link text-active-primary me-6 text-nowrap"
                onClick={() => handleOnClick(10)}
              >
                Documentos
              </span>
            </li>
            <li className="nav-item" role="button">
              <span
                id="btn11"
                className="nav-link text-active-primary me-6 text-nowrap"
                onClick={() => handleOnClick(11)}
              >
                Extratos
              </span>
            </li>
            {isClient ? (
              ""
            ) : (
              <>
                <li className="nav-item" role="button">
                  <span
                    id="btn12"
                    className="nav-link text-active-primary me-6 text-nowrap"
                    onClick={() => handleOnClick(12)}
                  >
                    Pagamentos
                  </span>
                </li>
                <li className="nav-item" role="button">
                  <span
                    id="btn13"
                    className="nav-link text-active-primary me-6 text-nowrap"
                    onClick={() => handleOnClick(13)}
                  >
                    Historico do Cliente
                  </span>
                </li>
              </>
            )}
          </ul>
        </ScrollBar>
      </div>
    </div>
  );
};
export default ProfileHeader;
