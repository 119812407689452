import Rails from "@rails/ujs";

export const clientData = (element, successCallback) => {
  return getData("/services/clients", element, successCallback);
};

export const dashboardData = (element, successCallback) => {
  return getData("/services/dashboard", element, successCallback, "line", {
    labels: {},
  });
};

export const financeData = (element, successCallback) => {
  return getData("/services/finances", element, successCallback, "bar");
};

export const assetsData = (element, successCallback) => {
  return getData("/services/assets", element, successCallback, "bar");
};

const getData = (url, element, successCallback, kind, config) => {
  var data = [];

  Rails.ajax({
    type: "GET",
    url: url,
    success: (result) => {
      successCallback(element, result, kind, config);
    },
  });

  return data;
};
