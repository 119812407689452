import React, { useState, useEffect, useContext } from "react";
import * as S from "../../style/styles";
import { GlobalContext } from "../contexts/GlobalState";
import { Loader } from "../elements/Loader";

const PerformanceHistory = ({ active, userId, isLoading }) => {
  const { states, setters } = useContext(GlobalContext);
  const { userPerformanceHistory } = states;
  const tabValue = 3;
  const isActive = active === tabValue;

  return (
    <S.DisplayContainer isActive={isActive} isLoading={isLoading}>
      <Loader />

      <div className="content-display">
        <div className="row g-5 g-xl-8">
          <div className="col-xl-6 ">
            <div className="card card-xl-stretch mb-5 mb-xl-8">
              <div className="card-header  border-bottom border-gray-200 pt-5">
                <div className="card-title align-items-start flex-column">
                  <h3 className="fw-bolder m-0">Retornos Acumulados</h3>
                </div>
              </div>
              <div className="card-body px-9 collapse show">
                <div className="">
                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <tbody>
                      <tr>
                        <td>-----</td>
                        <td>%</td>
                        <td>% CDI</td>
                      </tr>
                      <tr>
                        <td>No Mês</td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.accumulated.month &&
                            userPerformanceHistory.accumulated.month}
                        </td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.accumulated.month_cdi}
                        </td>
                      </tr>
                      <tr>
                        <td>No Ano</td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.accumulated.year}
                        </td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.accumulated.year_cdi}
                        </td>
                      </tr>
                      <tr>
                        <td>Últimos 12 meses</td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.accumulated.last_12}
                        </td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.accumulated.last_12_cdi}
                        </td>
                      </tr>
                      <tr>
                        <td>Últimos 24 meses</td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.accumulated.last_24}
                        </td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.accumulated.last_24_cdi}
                        </td>
                      </tr>
                      <tr>
                        <td>Histórico</td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.accumulated.total}
                        </td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.accumulated.total_cdi}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="card card-xl-stretch mb-5 mb-xl-8">
              <div className="card-header  border-bottom border-gray-200 pt-5">
                <div className="card-title align-items-start flex-column">
                  <h3 className="fw-bolder m-0">Retornos Mensais</h3>
                </div>
              </div>
              <div className="card-body px-9 collapse show">
                <div className="">
                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <tbody>
                      <tr>
                        <td>-----</td>
                        <td>%</td>
                        <td>% CDI</td>
                      </tr>
                      <tr>
                        <td>Maior Retorno Mensal</td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.monthly.best}
                        </td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.monthly.best_cdi}
                        </td>
                      </tr>
                      <tr>
                        <td>Maior Retorno Anual</td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.monthly.worst}
                        </td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.monthly.worst_cdi}
                        </td>
                      </tr>
                      <tr>
                        <td>Nº meses positivos/acima</td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.monthly.positives}
                        </td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.monthly.positives_cdi}
                        </td>
                      </tr>
                      <tr>
                        <td>Nº meses/abaixo</td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.monthly.negatives}
                        </td>
                        <td>
                          {userPerformanceHistory &&
                            userPerformanceHistory.accumulated &&
                            userPerformanceHistory.monthly.negatives_cdi}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.DisplayContainer>
  );
};

export default PerformanceHistory;
