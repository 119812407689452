import React, { useState, useRef, useEffect } from "react";
import { Input } from "reactstrap";
import $ from "jquery";
import moment from "moment";
import "daterangepicker";
import "moment/locale/pt-br";
import Cleave from "cleave.js";
import styled from "styled-components";

const InputDate = styled(Input)`
  .table-condensed tbody {
    display: none !important;
  }
`;

const DatepickerMonthTest = ({ name, onValueChange, ...props }) => {
  const [inputValue, setInputValue] = useState(props.value);
  const inputEl = useRef(null);

  useEffect(() => {
    $(inputEl.current)
      .daterangepicker(
        {
          singleDatePicker: true,
          showDropdowns: true,
          // minYear: 1900,
          // autoUpdateInput: true,
          autoApply: true,
          locale: {
            // applyLabel: "Aplicar",
            // cancelLabel: 'Cancelar',
            format: 'MMMM YYYY',
          },
        },
        function (start, end) {
          var dateValue = start.format('MMMM YYYY');
          setInputValue(dateValue);
          onValueChange(dateValue);
        }
      )
      .on("hide.daterangepicker", function (ev, picker) {
        $(".table-condensed tbody tr:nth-child(2) td").click();
        console.log(picker.startDate.format('MMMM YYYY'))
      });

   

    const maskInstance = new Cleave(inputEl.current, {
      date: true,
      datePattern: ["M", "Y"],
    });

    return () => {
      maskInstance.destroy();
    };

  }, []);

  return (
    <InputDate
      innerRef={inputEl}
      name={name}
      className="form-control form-control-solid"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
    />
  );
};

export default DatepickerMonthTest;
