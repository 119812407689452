import RemoteContent from './remote_content'
export default class extends RemoteContent {
  static values = { 
    contentData: {
      type: Object,
      default: { }
    },
    successMessage: {
      type: String,
      default: 'Usuário atualiazdo com sucesso'
    }
  }

  updateContentDataValue() {
    var htmlContent = ''
    
    if(Object.keys(this.contentDataValue).length !== 0 ){
      htmlContent = this.contentTemplate()
    }
    
    this.contentTarget.innerHTML = htmlContent
  }

  contentTemplate() {
      return `
          <tr>
            <td>Nome</td>
            <td>${this.contentDataValue.name}</td>
          </tr>
          <tr>
            <td>E-mail</td>
            <td>${this.contentDataValue.email}</td>
          </tr>
          <tr>
            <td>Telefone</td>
            <td>${this.contentDataValue.phone_with_ddi}</td>
          </tr>
          <tr>
            <td>CPF/CNPJ</td>
            <td>${this.contentDataValue.document}</td>
          </tr>
          <tr>
            <td>Nº do cliente</td>
            <td>${this.contentDataValue.code}</td>
          </tr>
          <tr>
            <td>Cidade</td>
            <td>${this.contentDataValue.city}</td>
          </tr>
          <tr>
            <td>Perfil definido</td>
            <td>${this.contentDataValue.profile}</td>
          </tr>
          <tr>
            <td>Data de Envio da Primeira Recomendação</td>
            <td>${this.contentDataValue.date_first_recommendation}</td>
          </tr>
          <tr>
            <td>Isento de pagamento</td>
            <td>${this.contentDataValue.string_payment_exempted}</td>
          </tr>
          <tr>
            <td>Data da cobrança</td>
            <td>${this.contentDataValue.billing_day}</td>
          </tr>
          <tr>
            <td>Ativo?</td>
            <td>${this.contentDataValue.string_status}</td>
          </tr>
          <tr>
            <td>Plano</td>
            <td>${this.contentDataValue.plan_name}</td>
          </tr>
          <tr>
            <td>Melhor dia para atualizar a carteira</td>
            <td>${this.contentDataValue.best_day_update_wallet}</td>
          </tr>
          <tr>
            <td>Forma de pagamento</td>
            <td>${this.contentDataValue.string_payment_method}</td>
          </tr>
          <tr>
            <td>Data de Nascimento</td>
            <td>${this.contentDataValue.formatted_birthday}</td>
          </tr>
          <tr>
            <td>Cep</td>
            <td>${this.contentDataValue.zipcode}</td>
          </tr>
          <tr>
            <td>Rua</td>
            <td>${this.contentDataValue.street}</td>
          </tr>
          <tr>
            <td>Número</td>
            <td>${this.contentDataValue.number}</td>
          </tr>
          <tr>
            <td>Complemento</td>
            <td>${this.contentDataValue.complement}</td>
          </tr>
          <tr>
            <td>Bairro</td>
            <td>${this.contentDataValue.neighborhood}</td>
          </tr>
          <tr>
            <td>Cidade</td>
            <td>${this.contentDataValue.city}</td>
          </tr>
          <tr>
            <td>Estado</td>
            <td>${this.contentDataValue.state}</td>
          </tr>
          <tr>
          <td>País</td>
          <td>${this.contentDataValue.country}</td>
        </tr>
     `
  }
}

