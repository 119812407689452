import React from "react";
import SelectField from "react-select";
import { useField } from "formik";

import { selectStyles } from "./styles";

const Select = (props) => {
  const [field, meta, helpers] = useField(props);

  const onChange = (value) => {
    if (typeof props.onChange === "function") {
      props.onChange(value);
    }
    helpers.setValue(value);
  };

  return (
    <SelectField
      styles={selectStyles(meta.error, meta.touched)}
      {...props}
      placeholder="Selecione"
      value={field.value}
      onChange={onChange}
      onBlur={() => {
        if (!meta.touched) {
          helpers.setTouched(true);
        }
      }}
      noOptionsMessage={() => "Nenhum resultado encontrado"}
    />
  );
};

export default Select;
