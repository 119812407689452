import { Controller } from "@hotwired/stimulus";
import { CustomApexCharts } from "../forms/charts";
import { assetsData } from "../resources/home/data";

export default class extends Controller {
  static targets = ["assert"];

  connect() {
    assetsData(this.assertTarget, CustomApexCharts);
  }
}
