import React, { useEffect, useState, useContext } from "react";
import * as S from "../../style/styles";

import { GlobalContext } from "../contexts/GlobalState";
import { editUserPersonalData } from "../../services/userProfileRequests";

import { Loader } from "../elements/Loader";
import ModalForm from "../modals/ModalForm";
import FormUpdatePersonalData from "../forms/PersonalDataForm";

const PersonalData = ({ active, userId, isLoading }) => {
  const { states, setters } = useContext(GlobalContext);
  const { userPersonalData } = states;
  const { setUserPersonalData } = setters;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tabValue = 0;
  const isActive = active === tabValue;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const editAction = (values) => {
    editUserPersonalData(userId, afterSubmitForm, values);
  };

  const afterSubmitForm = (data) => {
    setIsModalOpen(false);
    setUserPersonalData(data);
  };

  return (
    <S.DisplayContainer isActive={isActive} isLoading={isLoading}>
      <Loader />
      <div className="content-display">
        <div className="card mb-5 mb-xl-10">
          <div className="card-header  border-bottom border-gray-200 pt-5">
            <div className="card-title align-items-start flex-column">
              <h3 className="fw-bolder m-0">Dados Pessoais</h3>
            </div>
            <div className="card-toolbar">
              <button
                className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                onClick={() => setIsModalOpen(true)}
              >
                <i className="bi bi-pencil-square fs-7" />
              </button>
            </div>
          </div>
          <div className="card-body p-9 collapse show">
            <div className="row">
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <tbody>
                  <tr>
                    <td>Nome</td>
                    <td>{userPersonalData.name}</td>
                  </tr>
                  <tr>
                    <td>E-mail</td>
                    <td>{userPersonalData.email}</td>
                  </tr>
                  <tr>
                    <td>Telefone</td>
                    <td>{userPersonalData.phone_with_ddi}</td>
                  </tr>
                  <tr>
                    <td>CPF/CNPJ</td>
                    <td>{userPersonalData.document}</td>
                  </tr>
                  <tr>
                    <td>Data de Nascimento</td>
                    <td>{userPersonalData.formatted_birthday}</td>
                  </tr>
                  <tr className="pt-5">
                    <td>Nº do cliente</td>
                    <td>{userPersonalData.code}</td>
                  </tr>
                  <tr className="pt-5">
                    <td>Perfil definido</td>
                    <td>{userPersonalData.profile}</td>
                  </tr>
                  <tr>
                    <td>Data de Envio da Primeira Recomendação</td>
                    <td>{userPersonalData.date_first_recommendation}</td>
                  </tr>
                  <tr>
                    <td>Isento de pagamento</td>
                    <td>{userPersonalData.string_payment_exempted}</td>
                  </tr>
                  <tr>
                    <td>Data da cobrança</td>
                    <td>{userPersonalData.billing_day}</td>
                  </tr>
                  <tr>
                    <td>Ativo?</td>
                    <td>{userPersonalData.string_status}</td>
                  </tr>
                  <tr>
                    <td>Plano</td>
                    <td>{userPersonalData.plan_name}</td>
                  </tr>
                  <tr>
                    <td>Melhor dia para atualizar a carteira</td>
                    <td>{userPersonalData.best_day_update_wallet}</td>
                  </tr>
                  <tr>
                    <td>Forma de pagamento</td>
                    <td>{userPersonalData.string_payment_method}</td>
                  </tr>

                  <tr>
                    <td>Cep</td>
                    <td>{userPersonalData.zipcode}</td>
                  </tr>
                  <tr>
                    <td>Rua</td>
                    <td>{userPersonalData.street}</td>
                  </tr>
                  <tr>
                    <td>Número</td>
                    <td>{userPersonalData.number}</td>
                  </tr>
                  <tr>
                    <td>Complemento</td>
                    <td>{userPersonalData.complement}</td>
                  </tr>
                  <tr>
                    <td>Bairro</td>
                    <td>{userPersonalData.neighborhood}</td>
                  </tr>
                  <tr>
                    <td>Cidade</td>
                    <td>{userPersonalData.city}</td>
                  </tr>
                  <tr>
                    <td>Estado</td>
                    <td>{userPersonalData.state}</td>
                  </tr>
                  <tr>
                    <td>País</td>
                    <td>{userPersonalData.country}</td>
                  </tr>
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ModalForm
        isOpen={isModalOpen}
        toggle={toggleModal}
        label="Dados de usuário"
      >
        <FormUpdatePersonalData
          objectState={userPersonalData}
          onFormSubmit={editAction}
          closeModal={() => toggleModal()}
        />
      </ModalForm>
    </S.DisplayContainer>
  );
};

export default PersonalData;
