import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, ModalBody } from "reactstrap";
import { Formik, Form, Field } from "formik";
import bankAccount from "../../schemas/bankAccount.schema";
import { GlobalContext } from "../contexts/GlobalState";
import { Select, Input, Label, FormGroup } from "./controls";
import { getBanks } from "../../services/userProfileRequests";

const defaultValues = {
  id: null,
  account: "",
  agency: "",
  bank: {
    label: "",
    value: "",
  },
  name: "",
  email: "",
  phone: "",
  bank_manager_attributes: {
    email: "",
    id: null,
    name: "",
    phone: "",
  },
};
export const FormEditOrCreateBankAccount = ({
  objectState,
  onFormSubmit,
  closeModal,
}) => {
  const { states, setters } = useContext(GlobalContext);
  const { banks } = states;
  const { setBanks } = setters;

  const initialValues =
    Object.keys(objectState).length !== 0
      ? {
        ...objectState,
        name: objectState.bank_manager_attributes.name,
        email: objectState.bank_manager_attributes.email,
        phone: objectState.bank_manager_attributes.phone,
      }
      : defaultValues;
  const onSubmit = (values) => {
    onFormSubmit({
      id: values.id,
      account: values.account,
      agency: values.agency,
      bank_id: values.bank.value,
      bank_manager_attributes: {
        id: values.bank_manager_attributes.id,
        name: values.name,
        email: values.email,
        phone: values.phone,
      },
    });
  };
  const newBanks = banks.map((bank) => {
    return {
      value: bank.id,
      label: bank.name,
    };
  });
  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={bankAccount}
        onSubmit={onSubmit}
      >
        <Form>
          <ModalBody className="container p-10">
            <Row>
              <Col md={7}>
                <FormGroup name="bank">
                  <Label htmlFor="bank">
                    Banco
                    <abbr title="required">*</abbr>
                  </Label>
                  <Select name="bank" id="bank" options={newBanks} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup name="agency">
                  <Label htmlFor="agency" required>
                    Agência
                  </Label>
                  <Input name="agency" id="agency" />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup name="account">
                  <Label htmlFor="account" required>
                    Conta
                  </Label>
                  <Input name="account" id="account" />
                </FormGroup>
              </Col>
            </Row>
            <h2 className="my-10">Gerente</h2>
            <div className="separator my-4"></div>
            <Row>
              <Col md={6}>
                <FormGroup name="name">
                  <Label htmlFor="name">Nome</Label>
                  <Field
                    className="form-control form-control-solid"
                    name="name"
                    id="name"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup name="phone">
                  <Label htmlFor="phone">Telefone</Label>
                  <Field
                    className="form-control form-control-solid"
                    name="phone"
                    id="phone"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className=" my-2 form-group string user_name form-group-valid">
              <FormGroup name="email">
                <Label htmlFor="email">Email</Label>
                <Field
                  className="form-control form-control-solid"
                  name="email"
                  id="email"
                />
              </FormGroup>
            </div>
          </ModalBody>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}
            >
              Fechar
            </button>
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </Form>
      </Formik>
    </Fragment>
  );
};
