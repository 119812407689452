import React from "react";
import { Input as BSInput } from "reactstrap";
import classNames from "classnames";

const Input = ({ ...props }) => {
  const inputClass = classNames("form-control form-control-solid");

  return <BSInput className={inputClass} {...props} />;
};

export default Input;
