import RemoteContent from './remote_content'
export default class extends RemoteContent {
  static values = { 
    successMessage: {
      type: String,
      default: 'Conta bancária criada/alterada com sucesso'
    }
  }

  contentTemplate(bankAccount, index) {
      return `
      <tr >
        <td>${ bankAccount.bank.name }</td>
        <td>${ bankAccount.agency }</td>
        <td>${ bankAccount.account }</td>
        <td>${ bankAccount.bank_manager.name }</td>
        <td>${ bankAccount.bank_manager.email }</td>
        <td>${ bankAccount.bank_manager.phone }</td>
        <td class="text-end">       
          ${this.editResourceButton(`/services/users/${bankAccount.user_id}/bank_accounts/${bankAccount.id}/edit`, 'bank-account-modal', 'bank-accounts' )}
          ${this.destroyResourceButton(`/services/users/${bankAccount.user_id}/bank_accounts/${bankAccount.id}`, 'bank-accounts' )}
        </td>
      </tr>
     `
  }
}

