import axios, { getResponseError } from "../services/axiosRequest";

export const index = (setter) => {
  axios
    .get(`/investment_classes`)
    .then((result) => {
      setter(result.data);
    })
    .catch(getResponseError);
};
