import RemoteContent from "./remote_content";

export default class extends RemoteContent {
  static values = {
    contentData: {
      type: Object,
      default: {
        accumulated: {},
        monthly: {},
      },
    },
  };

  connect() {
    this.loadContent();
  }

  updateContentDataValue() {
    this.contentTarget.innerHTML = this.contentTemplate();
  }

  contentTemplate() {
    return `
      <div class="col-md-6">
        <h4>Retornos Acumulados</h4>
        <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
          <thead>
            <tr>
              <th></th>
              <th>%</th>
              <th>% CDI</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>No Mês</td>
              <td>${this.contentDataValue.accumulated.month}</td>
              <td>${this.contentDataValue.accumulated.month_cdi}</td>
            </tr>
            <tr>
              <td>No Ano</td>
              <td>${this.contentDataValue.accumulated.year}</td>
              <td>${this.contentDataValue.accumulated.year_cdi}</td>
            </tr>
            <tr>
              <td>Últimos 12 meses</td>
              <td>${this.contentDataValue.accumulated.last_12}</td>
              <td>${this.contentDataValue.accumulated.last_12_cdi}</td>
            </tr>
            <tr>
              <td>Últimos 24 meses</td>
              <td>${this.contentDataValue.accumulated.last_24}</td>
              <td>${this.contentDataValue.accumulated.last_24_cdi}</td>
            </tr>
            <tr>
              <td>Histórico</td>
              <td>${this.contentDataValue.accumulated.total}</td>
              <td>${this.contentDataValue.accumulated.total_cdi}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div class="col-md-6">
        <h4>Retornos Mensais</h4>
        <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
          <thead>
            <tr>
              <th></th>
              <th>%</th>
              <th>% CDI</th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <td>Maior Retorno Mensal</td>
            <td>${this.contentDataValue.monthly.best}</td>
            <td>${this.contentDataValue.monthly.best_cdi}</td>
          </tr>
          <tr>
            <td>Menor Retorno Mensal</td>
            <td>${this.contentDataValue.monthly.worst}</td>
            <td>${this.contentDataValue.monthly.worst_cdi}</td>
          </tr>
          <tr>
            <td>Nº meses positivos/acima</td>
            <td>${this.contentDataValue.monthly.positives}</td>
            <td>${this.contentDataValue.monthly.positives_cdi}</td>
          </tr>
          <tr>
            <td>Nº meses negativos/abaixo</td>
            <td>${this.contentDataValue.monthly.negatives}</td>
            <td>${this.contentDataValue.monthly.negatives_cdi}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>`;
  }
}
