import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, ModalBody } from "reactstrap";
import { Formik, Form, Field } from "formik";

import { GlobalContext } from "../contexts/GlobalState";
import { consolidatedPortfolio } from "../../api/consolidatedPortfolio";
import { Loader } from "../elements/Loader";
import ConsolidateWallet from "../elements/ConsolidateWallet";

import {
  Select,
  Input,
  Label,
  FormGroup,
  Datepicker,
  Number,
} from "./controls";
import { SaveButton } from "../../style/styles";

const defaultValue = {
  investment_class: {},
  ctr: "",
  investment_subclass: {},
  investment_kind: {},
  name: "",
  liquidity: 0,
  quote: {},
  index: 100,
  aa: 0,
  end: "",
  investment_profile: {},
  investment_template: {},
  bank: {},
  transaction_total: 0,
  transaction_pu: 0,
  transaction_day: "",
  chargeable: true,
};

const InvestmentForm = ({
  userId,
  isNewInvestment,
  investmentClasses,
  quantumInvestments,
  investmentProfile,
  toggleModal,
  onFormSubmit,
  quotes,
}) => {
  const [checked, setChecked] = useState(true);
  const { states } = useContext(GlobalContext);
  const { banks, investmentTemplates } = states;
  const [banksOptions, setBanksOptions] = useState([]);
  const [consolidateWallet, setConsolidatedWallet] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const [investmentSubclasses, setInvestmentSubclasses] = useState(
    investmentClasses[0].investment_subclasses
  );
  const [investmentKinds, setInvestmentKinds] = useState(
    investmentSubclasses[0].investment_kinds
  );

  const onInvestmentClassesChange = (value) => {
    var investmentClass = investmentClasses.find((element) => {
      return element.value == value.value;
    });

    const values = investmentClass ? investmentClass.investment_subclasses : {};

    setInvestmentSubclasses(values);
  };

  const onInvestmentSubClassesChange = (value) => {
    var investmentSubClass = investmentSubclasses.find((element) => {
      return element.value == value.value;
    });

    const values = investmentSubClass
      ? investmentSubClass.investment_kinds
      : {};

    setInvestmentKinds(values);
  };

  const onCtrChange = (value) => {
    setShowLoading(true);
    consolidatedPortfolio(value.value, setConsolidatedWallet, setShowLoading);
  };

  const onSubmit = (values) => {
    setIsLoading(true)
    var params = {
      ...values,
      bank_id: values.bank.value,
      investment_transactions_attributes: [
        {
          pu: values.transaction_pu,
          total: values.transaction_total,
          day: values.transaction_day,
        },
      ],
    };
    if (!isNewInvestment) {
      params = {
        ...params,
        investment_template_id: values.investment_template.value,
      };
    } else {
      params = {
        ...params,
        investment_class_id: values.investment_class.value,
        investment_profile_id: values.investment_profile.value,
        investment_subclass_id: values.investment_subclass.value,
        investment_kind_id: values.investment_kind.value,
        ctr: values.ctr.value,
        quote_id: values.quote.value,
        investments_attributes: [
          {
            user_id: userId,
            bank_id: values.bank.value,
            chargeable: values.chargeable,
            investment_transactions_attributes: [
              {
                pu: values.transaction_pu,
                total: values.transaction_total,
                day: values.transaction_day,
              },
            ],
          },
        ],
      };
    }
    onFormSubmit(params, setIsLoading);
  };
  useEffect(() => {
    banks.forEach((bank) => {
      banksOptions.push({
        id: bank.id,
        label: bank.name,
        value: bank.id,
      });
    });

    setBanksOptions(banksOptions);
  }, [banks]);

  return (
    <Fragment>
      <Formik
        initialValues={defaultValue}
        onSubmit={onSubmit}
      // validationSchema={bankAccount}
      >
        <Form>
          <ModalBody className="container">
            <div className="px-3 py-5">
              {isNewInvestment ? (
                <Fragment>
                  <Row className="g-9 mb-6">
                    <Col md={6} className="fv-row">
                      <FormGroup name="investment_class">
                        <Label htmlFor="investment_class">
                          Classe
                          <abbr title="required">*</abbr>
                        </Label>
                        <Select
                          name="investment_class"
                          id="investment_class"
                          onChange={onInvestmentClassesChange}
                          options={investmentClasses}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} className="fv-row">
                      <FormGroup name="ctr">
                        <Label htmlFor="ctr">
                          CNPJ/ISIN
                          <abbr title="required">*</abbr>
                        </Label>
                        <Select
                          onChange={onCtrChange}
                          name="ctr"
                          id="ctr"
                          options={quantumInvestments}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {showLoading ? (
                    <Loader />
                  ) : consolidateWallet.length ? (
                    <ConsolidateWallet consolidateWallet={consolidateWallet} />
                  ) : (
                    <Fragment>
                      <Row className="g-9 mb-6">
                        <Col md={6} className="fv-row">
                          <FormGroup name="investment_subclass">
                            <Label htmlFor="investment_subclass">
                              SubClasse
                              <abbr title="required">*</abbr>
                            </Label>
                            <Select
                              name="investment_subclass"
                              id="investment_subclass"
                              options={investmentSubclasses}
                              onChange={onInvestmentSubClassesChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={6} className="fv-row">
                          <FormGroup name="investment_kind">
                            <Label htmlFor="investment_kind">
                              Tipo
                              <abbr title="required">*</abbr>
                            </Label>
                            <Select
                              name="investment_kind"
                              id="investment_kind"
                              options={investmentKinds}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="g-9 mb-6">
                        <Col md={6} className="fv-row">
                          <FormGroup name="name">
                            <Label htmlFor="name">
                              Nome
                              <abbr title="required">*</abbr>
                            </Label>
                            <Field
                              className="form-control form-control-solid"
                              name="name"
                              id="name"
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6} className="fv-row">
                          <FormGroup name="liquidity">
                            <Label htmlFor="liquidity">
                              Liquídez (dias)
                              <abbr title="required">*</abbr>
                            </Label>
                            <Field
                              className="form-control form-control-solid"
                              name="liquidity"
                              id="liquidity"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="g-9 mb-6">
                        <Col md={6} className="fv-row">
                          <FormGroup name="quote">
                            <Label htmlFor="quote">
                              Indexador
                              <abbr title="required">*</abbr>
                            </Label>
                            <Select name="quote" id="quote" options={quotes} />
                          </FormGroup>
                        </Col>

                        <Col md={6} className="fv-row">
                          <FormGroup name="index">
                            <Label htmlFor="index">
                              Porcentagem do indexador
                              <abbr title="required">*</abbr>
                            </Label>
                            <Field
                              className="form-control form-control-solid"
                              name="index"
                              id="index"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="g-9 mb-6">
                        <Col md={6} className="fv-row">
                          <FormGroup name="aa">
                            <Label htmlFor="aa">Porcentagem Extra</Label>
                            <Field
                              className="form-control form-control-solid"
                              name="aa"
                              id="aa"
                            />
                          </FormGroup>
                        </Col>

                        <Col md={6} className="fv-row">
                          <FormGroup name="end">
                            <Label htmlFor="end">Data de Vencimento</Label>
                            <Datepicker
                              className="form-control form-control-solid"
                              name="end"
                              id="end"
                              restrictDates={true}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                  <Row className="g-9 mb-6">
                    <Col md={12} className="fv-row">
                      <FormGroup name="investment_profile">
                        <Label htmlFor="investment_profile">
                          Perfil
                          <abbr title="required">*</abbr>
                        </Label>
                        <Select
                          name="investment_profile"
                          id="investment_profile"
                          options={investmentProfile}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Fragment>
              ) : (
                ""
              )}
              <Row className="g-9 mb-6">
                {!isNewInvestment ? (
                  <Col md={6} className="fv-row">
                    <FormGroup name="investment_template">
                      <Label htmlFor="investment_template">
                        Ativo
                        <abbr title="required">*</abbr>
                      </Label>
                      <Select
                        name="investment_template"
                        id="investment_template"
                        options={investmentTemplates}
                      />
                    </FormGroup>
                  </Col>
                ) : (
                  ""
                )}
                <Col md={6} className="fv-row">
                  <FormGroup name="bank">
                    <Label htmlFor="bank">
                      Banco
                      <abbr title="required">*</abbr>
                    </Label>
                    <Select name="bank" id="bank" options={banksOptions} />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="g-9 mb-6">
                <Col md={6} className="fv-row">
                  <FormGroup name="transaction_total">
                    <Label htmlFor="transaction_total" required>
                      Valor
                    </Label>
                    <Number name="transaction_total" id="transaction_total" />
                  </FormGroup>
                </Col>

                <Col md={6} className="fv-row">
                  <FormGroup name="transaction_pu">
                    <Label htmlFor="transaction_pu" required>
                      PU
                    </Label>
                    <Number name="transaction_pu" id="transaction_pu" />
                  </FormGroup>
                </Col>

                <Col md={6} className="fv-row">
                  <FormGroup name="transaction_day">
                    <Label htmlFor="transaction_day">
                      Data da Movimentação
                    </Label>
                    <Datepicker
                      className="form-control form-control-solid"
                      name="transaction_day"
                      id="transaction_day"
                      restrictDates={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="g-9 mb-6">
                <Col md={6} className="fv-row">
                  <FormGroup name="chargeable">
                    <label htmlFor="chargeable">Cobrado?</label>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="chargeable"
                        checked={checked}
                        onChange={(e) => setChecked(!checked)}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={toggleModal}
            >
              Fechar
            </button>
            <SaveButton type="submit" className="btn btn-primary">
              {isLoading ?
                <Loader />
                :
                'Salvar'}
            </SaveButton>
          </div>
        </Form>
      </Formik>
    </Fragment>
  );
};

export default InvestmentForm;
