import axios from 'axios'
import { errorMessage } from './toast';

const csrfToken = document.querySelector('[name="csrf-token"]').content;

axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
// axios.defaults.timeout = 10000
axios.defaults.baseURL = '/services'

export default axios

export const getResponseError = err => {
  let message = ''
  if (err.response.data.errors && err.response.data.errors.length) {
    message = err.response.data.errors.join(', ')
  } else {
    message = 'Error na comunicação com o servidor.'
  }

  errorMessage(message)
}