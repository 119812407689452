import $ from "jquery";
import "select2";

const defaultOptions = {
  placeholder: "Selecione",
  theme: "bootstrap5",
  width: "100%",
  selectionCssClass: ":all:",
  language: {
    inputTooShort: () => "Digite pelo menos 3 caracteres",
    noResults: () => "Nenhum resultado encontrado",
    searching: () => "Pesquisando...",
  },
};

export const select2 = (options) => {
  $(".custom-select2").select2({
    ...defaultOptions,
    ...options,
  });

  $(".custom-select2").on("select2:select", function () {
    let event = new Event("change", { bubble: true });
    this.dispatchEvent(event);
  });
};
export const filterSelect2 = (options) => {
  const parent = $("#modal-filter").length
    ? { dropdownParent: $("#modal-filter") }
    : {};
  $(".filter-select2").select2({
    ...defaultOptions,
    allowClear: true,
    ...options,
    ...parent,
  });

  $(".filter-select2").on("select2:select", function () {
    let event = new Event("change", { bubble: true });
    this.dispatchEvent(event);
  });
};

export const remoteSelect2 = (
  selector,
  url,
  params,
  processResults,
  options
) => {
  $(selector).select2({
    ...defaultOptions,
    allowClear: true,
    ajax: {
      url: url,
      dataType: "json",
      data: function (element) {
        return {
          search: element.term,
          ...params,
        };
      },
      processResults: function (data) {
        return {
          results: processResults(data),
        };
      },
    },
    minimumInputLength: 3,
    ...options,
  });

  $(selector).on("select2:select", function () {
    let event = new Event("change", { bubble: true });
    this.dispatchEvent(event);
  });

  $(selector).on("select2:unselecting", function (e) {
    let event = new Event("change", { bubble: true });
    this.dispatchEvent(event);
  });
};

export default () => {
  select2();
  filterSelect2();
};
