import * as Yup from "yup";
import "./locale";

export default () =>
  Yup.object().shape({
    name: Yup.string(),
    email: Yup.string().email(),
    code: Yup.number().positive(),
    plan: Yup.object(),
    billing_day: Yup.object(),
    formatted_birthday: Yup.string(),
    payment_method: Yup.object(),
    identity: Yup.string(),
    phone: Yup.string(),
    ddi: Yup.number(),
    city: Yup.string(),
  });
