import axios, { getResponseError } from "../services/axiosRequest";
import { successMessage } from "../utils/toast";

export const index = (userId, InvestmentId, setter, setLoading) => {
  axios
    .get(
      `users/${userId}/user_investments/${InvestmentId}/investment_transactions/`
    )
    .then((result) => {
      setter(result.data);
      setLoading && setLoading(false)
    })
    .catch(getResponseError);
};

export const create = (userId, InvestmentId, setter, data, setLoading) => {
  axios
    .post(
      `users/${userId}/user_investments/${InvestmentId}/investment_transactions/`,
      data
    )
    .then(() => {
      successMessage("Transação criada com sucesso");
      index(userId, InvestmentId, setter);
      setLoading(false)
    })
    .catch(getResponseError);
};

export const update = (userId, InvestmentId, setter, data, setLoading) => {
  axios
    .patch(
      `users/${userId}/user_investments/${InvestmentId}/investment_transactions/${data.id}`,
      data
    )
    .then(() => {
      successMessage("Transação atualizada com sucesso");
      index(userId, InvestmentId, setter);
      setLoading(false)
    })
    .catch(getResponseError);
};

export const destroy = (userId, InvestmentId, setter, transaction, setLoading) => {
  axios
    .delete(
      `users/${userId}/user_investments/${InvestmentId}/investment_transactions/${transaction.id}`
    )
    .then(() => {
      successMessage("Transação excluída com sucesso");
      index(userId, InvestmentId, setter, setLoading);
    })
    .catch(getResponseError);
};
