import React, { Fragment, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { Select, Input } from "../controls";
import { index as getInvestments } from "../../api/allInvestment";
import { create } from "../../api/suggestions";
import SuggestionResult from "../tables/SuggestionResult";

export default ({
  activesSelected,
  investmentTemplates,
  setShowFromElements,
  isLoading,
  setIsLoading,
}) => {
  const [investment, setInvestment] = useState({});
  const [percentage, setPercentage] = useState(0);
  const [investments, setInvestments] = useState([]);

  const onClick = () => {
    setIsLoading(true);
    getInvestments(
      setInvestments,
      {
        investment_id: activesSelected.map((element) => element.id),
        percentage: percentage,
        filter: "wallet_preview",
      },
      setIsLoading
    );
  };

  const onSubmit = () => {
    create({
      investment_id: activesSelected.map((element) => element.id),
      percentage: percentage,
      investment_template_id: investment,
    });
  };

  return (
    <Fragment>
      <h3 className="mb-5">Parâmetros </h3>
      <div className="separator my-4"></div>
      <Row className="mb-5">
        <Col md={4}>
          <label htmlFor="client">Selecione o ativo:</label>
          <Select
            id="investment"
            options={investmentTemplates}
            onChange={(e) => setInvestment(e.value)}
          />
        </Col>
        <Col md={4}>
          <label htmlFor="client">Digite a porcentagem:</label>
          <Input id="client" onChange={(e) => setPercentage(e.target.value)} />
        </Col>
        <Col md={2}>
          <button
            type="button"
            className="btn btn-primary mt-6"
            onClick={onClick}
          >
            Simular
          </button>
        </Col>
      </Row>

      <Row>
        <SuggestionResult
          label="Resultado"
          content={investments}
          onClick={onSubmit}
          isLoading={isLoading}
        />
      </Row>
    </Fragment>
  );
};
