import React, { Fragment, useEffect, useState, useContext } from "react";
import { ModalBody, ModalFooter } from "reactstrap";

import { SwalButton } from "../buttons";
import ModalForm from "../modals/ModalForm";
import { InvestmentTransactionForm } from "../forms";
import { GlobalContext } from "../contexts/GlobalState";
import { getUserData } from "../../services/userProfileRequests";
import {
  index,
  create,
  update,
  destroy,
} from "../../api/investmentTransactions";
import { Loader } from "./Loader";

const InvestmentTransaction = ({
  userId,
  investment,
  closeModal,
  updateData,
}) => {
  const { states, setters } = useContext(GlobalContext);
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState({});
  const [formAction, setFormAction] = useState(() => {});
  const { isClient } = states;

  useEffect(() => {
    index(userId, investment.id, setTransactions);
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const setEditAction = (transaction) => {
    setFormAction(() => editAction);
    setCurrentTransaction(transaction);
    setIsModalOpen(true);
  };

  const setCreateAction = () => {
    setFormAction(() => creatAction);
    setCurrentTransaction({});
    setIsModalOpen(true);
  };

  const creatAction = (values, setLoading) => {
    create(userId, investment.id, afterSubmitForm, values, setLoading);
  };

  const editAction = (values, setLoading) => {
    update(userId, investment.id, afterSubmitForm, values, setLoading);
  };

  const destroyAction = (transaction) => {
    setIsLoading(true);
    destroy(userId, investment.id, afterSubmitForm, transaction, setIsLoading);
  };

  const afterSubmitForm = (data) => {
    setTransactions(data);
    setIsModalOpen(false);
    updateData();
  };

  return (
    <Fragment>
      <ModalBody className="container p-10">
        {isLoading ? (
          <Loader />
        ) : (
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <tbody>
              <tr>
                <th>Data</th>
                <th>Valor</th>
                <th>PU</th>
                <th>Criação</th>
                <th className="text-end">Ações</th>
              </tr>
              {transactions &&
                transactions.map((transaction, index) => {
                  return (
                    <tr key={index}>
                      <td>{transaction.day}</td>
                      <td>{transaction.total}</td>
                      <td>{transaction.pu}</td>
                      <td>{transaction.created_at}</td>
                      <td className="text-end">
                        {isClient ? (
                          ""
                        ) : (
                          <div className="me-0">
                            <button
                              className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2"
                              onClick={() => setEditAction(transaction)}
                            >
                              <i className="bi bi-pencil-square fs-7"></i>
                            </button>
                            <SwalButton
                              className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                              onSuccess={() => destroyAction(transaction)}
                              swalProps={{
                                title: "Você deseja excluir a conta bancária?",
                              }}
                            >
                              <i className="bi bi-trash fs-7"></i>
                            </SwalButton>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={closeModal}
        >
          Fechar
        </button>
        {isClient ? (
          ""
        ) : (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={setCreateAction}
          >
            <i className="bi bi-plus fs-7"></i> Nova
          </button>
        )}
      </ModalFooter>
      <ModalForm isOpen={isModalOpen} toggle={toggleModal} label="Transações">
        <InvestmentTransactionForm
          objectState={currentTransaction}
          onFormSubmit={formAction}
          closeModal={() => toggleModal()}
        />
      </ModalForm>
    </Fragment>
  );
};

export default InvestmentTransaction;
