import React, { useEffect, useState, useContext } from "react";
import { DatepickerMonth } from "../forms/controls";
import { getUserData } from "../../services/userProfileRequests";

import moment from "moment";

import * as S from "../../style/styles";
import { GlobalContext } from "../contexts/GlobalState";
import { Loader } from "../elements/Loader";
import DatepickerMonthTest from "../forms/controls/DatepickerMonthTest";

import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const History = ({ active, userId, isLoading, setIsLoading }) => {
  const { states, setters } = useContext(GlobalContext);
  const { userHistory } = states;
  const { setUserHistory } = setters;
  const tabValue = 2;
  const isActive = active === tabValue;
  const [value, setValue] = React.useState(new Date());

  const loadHistory = (data) => {
    // setValue(data)
    console.log(data);
    setIsLoading(true);
    getUserData(
      userId,
      setUserHistory,
      `/histories?date=${data}`,
      setIsLoading
    );
  };

  return (
    <S.DisplayContainer isActive={isActive} isLoading={isLoading}>
      <Loader />

      <div className="content-display">
        <div className="card mb-5 mb-xl-10">
          <div className="card-header  border-bottom border-gray-200 pt-5">
            <div className="card-title align-items-start flex-column">
              <h3 className="fw-bolder m-0">Histórico</h3>
            </div>

            <div className="card-toolbar">
              <DatepickerMonth
                value={moment(userHistory.last_movement).format("DD/MM/YYYY")}
                onValueChange={loadHistory}
              />
            </div>
          </div>

          <div className="card-body p-9 ">
            <div className="">
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <tbody>
                  <tr>
                    <td>Última movimentação</td>
                    <td>{userHistory.last_movement}</td>
                  </tr>
                  <tr>
                    <td>Patrimônio</td>
                    <td>{userHistory.total}</td>
                  </tr>
                  <tr>
                    <td>Retorno no Mês</td>
                    <td>{userHistory.month_rate_return}</td>
                  </tr>
                  <tr>
                    <td>Retorno no mês (%CDI)</td>
                    <td>{userHistory.cdi_rate}</td>
                  </tr>
                  <tr>
                    <td>Retorno no mês (R$)</td>
                    <td>{userHistory.monetize_rate}</td>
                  </tr>
                  <tr>
                    <td>Primeira Movimentação:</td>
                    <td>{userHistory.first_movement}</td>
                  </tr>
                  <tr>
                    <td>Última atualização:</td>
                    <td>{userHistory.date}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </S.DisplayContainer>
  );
};

export default History;
