import React from "react"
import { useField } from "formik"
import classNames from 'classnames'

const Switch = ({ children, ...props }) => {
  const [field] = useField(props);

  const checkboxClass = classNames({
    checked: !!field.value
  })

  return (
    <div className="form-check">
      <label className="form-check-label">
        <div className="uniform-checker">
          <span className={checkboxClass}>
            <input
              type="checkbox"
              className="form-check-input-styled"
              {...props}
              {...field}
            />
          </span>
        </div>
        { children }
      </label>
    </div>
  );
};

export default Switch;