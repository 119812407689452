import React, { useEffect, useState, useContext } from "react";
import * as S from "../../style/styles";

import TableRemoteContent from "../tables/TableRemoteContent";
import { Loader } from "../elements/Loader";
import ModalForm from "../modals/ModalForm";
import { GlobalContext } from "../contexts/GlobalState";
import IncomeForm from "../forms/IncomeForm";
import { editUserIncome } from "../../services/userProfileRequests";

const Income = ({ active, userId, isLoading }) => {
  const { states, setters } = useContext(GlobalContext);
  const { userIncome } = states;
  const { setUserIncome } = setters;

  const tabValue = 8;
  const isActive = active === tabValue;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const editAction = (values) => {
    editUserIncome(userId, afterSubmitForm, values);
  };

  const afterSubmitForm = (data) => {
    setIsModalOpen(false);
    setUserIncome(data);
  };
  return (
    <S.DisplayContainer isActive={isActive} isLoading={isLoading}>
      <Loader />

      <div className="content-display">
        <TableRemoteContent
          cardTitle="Renda"
          isEditable={true}
          actionType={'edit'}
          setIsModalOpen={setIsModalOpen}

        >
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead></thead>
            <tbody>
              <tr>
                <td>Renda Fixa Mensal</td>
                <td>R$ {userIncome.month_rev}</td>
              </tr>
              <tr>
                <td>Outro tipo de renda</td>
                <td>R$ {userIncome.month_rev_other}</td>
              </tr>
              <tr>
                <td>Renda não mensal</td>
                <td>R$ {userIncome.variable_rev}</td>
              </tr>
            </tbody>
          </table>
        </TableRemoteContent>

        <ModalForm isOpen={isModalOpen} toggle={toggleModal} title="Renda">
          <IncomeForm objectState={userIncome} onFormSubmit={editAction} toggleModal={toggleModal} />
        </ModalForm>
      </div>


    </S.DisplayContainer>
  );
};

export default Income;
