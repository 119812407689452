import $ from 'jquery'
import axios, { getResponseError } from '../utils/request'

const ZIPCODE = '.zipcode'
const ADDRESS_FIELDS = ['address', 'district', 'city', 'state']

const zipcode = () => {
  $(ZIPCODE).on('change', function() {
    handleChanges($(this))
  })
}

const handleChanges = element => {
  if (element.val() === null || element.val() === '') {
    return true
  }

  getAddress(element.val())
}

const getAddress = zipcode => {
  axios.get(`/zipcode/${zipcode}`)
    .then(setAddressAttributes)
    .catch(getResponseError)
}

const setAddressAttributes = data => {
  ADDRESS_FIELDS.forEach(e => {
    $(`.${e}`).val(data.data[e])
  })

  $('.country').val(data.data.country).trigger("change")
}

export default zipcode