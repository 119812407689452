import loadJqueryContent from './jqueryContent'


class Form {
  constructor() {
    document.addEventListener('DOMContentLoaded', this.init)
  }

  init() {
    loadJqueryContent()
  }
}

export default Form
