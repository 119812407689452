import pagarme from "pagarme";

import { Controller } from "@hotwired/stimulus";
import { errorMessage } from "../utils/toast";

export default class extends Controller {
  static targets = [
    "cardNumber",
    "holderName",
    "expirationDate",
    "code",
    "form",
    "cardHash",
  ];
  static values = {
    encryptionKey: {
      type: String,
      default: "",
    },
  };

  createCardToken(event) {
    event.preventDefault();
    const self = this;
    pagarme.client
      .connect({ encryption_key: self.encryptionKeyValue })
      .then((client) => {
        return client.security.encrypt({
          card_number: self.cardNumberTarget.value.replace(/[^A-Z0-9]+/gi, ""),
          card_holder_name: self.holderNameTarget.value,
          card_expiration_date: self.expirationDateTarget.value.replace(
            /[^A-Z0-9]+/gi,
            ""
          ),
          card_cvv: self.codeTarget.value,
        });
      })
      .then((card_hash) => {
        self.cardHashTarget.value = card_hash;
        self.cleanUpCardFields();
        self.formTarget.submit();
      })
      .catch((error) => {
        errorMessage(error);
      });
  }

  cleanUpCardFields() {
    this.cardNumberTarget.readOnly = true;
    this.holderNameTarget.readOnly = true;
    this.expirationDateTarget.readOnly = true;
    this.codeTarget.readOnly = true;
  }
}
