import React from 'react'
import { FormGroup as BSFormGroup, FormText } from 'reactstrap'
import { useField } from 'formik'

const FormGroup = ({ children, ...props }) => {
  const { name, ...formGroupProps } = props
  const [ ,meta ] = useField(props)
  
  return (
    <BSFormGroup {...formGroupProps}>
      { children }
      { meta.touched && meta.error ? <FormText color="danger" tag="span">{ meta.error }</FormText> : null }
    </BSFormGroup>
  )
}

export default FormGroup
