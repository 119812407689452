import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, ModalBody, ModalFooter } from "reactstrap";
import { Formik, Form, Field } from "formik";
import axios from "axios";

import PersonalSchema from "../../schemas/editPersonalData.schema.js";
import { getPlans, getAdress } from "../../services/userProfileRequests";
import { GlobalContext } from "../contexts/GlobalState";
import {
  Select,
  Input,
  Label,
  Number,
  FormGroup,
  Datepicker,
} from "./controls";

const PersonalDataForm = ({ objectState, onFormSubmit, closeModal }) => {
  const { states, setters } = useContext(GlobalContext);
  const { setUserPersonalData } = setters;
  const { isClient } = states;
  const [paymentPlans, setPaymentPlans] = useState([]);
  const [address, setAddress] = useState({});

  debugger;

  const initialValues = {
    ...objectState,
    plan: objectState.plan_object,
    payment_method: objectState.payment_method_object,
    billing_day: objectState.billing_day_object,
  };

  const paymentMethods = [
    {
      value: "credit_card",
      label: "Cartão de crédito",
    },
  ];

  const billingDay = [
    {
      value: 10,
      label: "Dia 10",
    },
    {
      value: 20,
      label: "Dia 20",
    },
    {
      value: 28,
      label: "Dia 28",
    },
  ];

  const onBlurZipcode = (event, setFieldValue) => {
    const zipcode = event.target?.value?.replace(/[^0-9]/g, "");

    if (zipcode?.length !== 8) {
      return;
    }

    axios
      .get(`/zipcode/${zipcode}`)
      .then((res) => {
        setFieldValue("street", res.data.address);
        setFieldValue("neighborhood", res.data.district);
        setFieldValue("city", res.data.city);
        setFieldValue("state", res.data.state);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPlans(setPaymentPlans);
  }, []);

  const onSubmit = (values) => {
    onFormSubmit({
      ...values,
      plan_id: values.plan.value,
      payment_method: values.payment_method.value,
      billing_day: values.billing_day.value,
    });
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={PersonalSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <ModalBody className="container p-10">
              <Row className="my-3">
                <Col md={6}>
                  <FormGroup name="name">
                    <Label htmlFor="name" required>
                      Nome
                      <abbr title="required">*</abbr>
                    </Label>
                    <Input name="name" id="name" />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup name="email">
                    <Label htmlFor="email" required>
                      Email
                      <abbr title="required">*</abbr>
                    </Label>
                    <Input name="email" id="email" />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="my-3">
                <Col md={6}>
                  <FormGroup name="password">
                    <Label htmlFor="password">Senha</Label>
                    <Input name="password" id="password" type="password" />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup name="formatted_birthday">
                    <Label htmlFor="formatted_birthday" required>
                      Data de Nascimento
                    </Label>
                    <Datepicker
                      name="formatted_birthday"
                      id="formatted_birthday"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="my-3">
                <Col md={2}>
                  <FormGroup name="ddi">
                    <Label htmlFor="ddi" required>
                      DDI
                    </Label>
                    <Input name="ddi" id="ddi" />
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup name="phone">
                    <Label htmlFor="phone" required>
                      Telefone
                    </Label>
                    <Input name="phone" id="phone" />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup name="document">
                    <Label htmlFor="document" required>
                      CPF/CNPJ
                    </Label>
                    <Input name="document" id="document" />
                  </FormGroup>
                </Col>
              </Row>
              {isClient ? (
                ""
              ) : (
                <>
                  <Row className="my-3">
                    <Col md={6}>
                      <FormGroup name="code">
                        <Label htmlFor="code" required>
                          Código
                        </Label>
                        <Input name="code" id="code" />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup name="partner_id">
                        <Label htmlFor="partner_id">ID Quantum</Label>
                        <Input name="partner_id" id="partner_id" />
                      </FormGroup>
                    </Col>
                  </Row>

                  <h2 className="my-10">Dados de Pagamento</h2>
                  <div className="separator my-4"></div>

                  <Row className="my-3">
                    <Col md={6}>
                      <FormGroup name="plan">
                        <Label htmlFor="plan" required>
                          Plano
                        </Label>
                        <Select name="plan" id="plan" options={paymentPlans} />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup name="billing_day">
                        <Label htmlFor="billing_day" required>
                          Dia de pagamento
                        </Label>
                        <Select
                          name="billing_day"
                          id="billing_day"
                          options={billingDay}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col md={6}>
                      <FormGroup name="payment_method">
                        <Label htmlFor="payment_method" required>
                          Método de pagamento
                        </Label>
                        <Select
                          name="payment_method"
                          id="payment_method"
                          options={paymentMethods}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup name="date_first_recommendation">
                        <Label htmlFor="date_first_recommendation">
                          Primeira Recomendação
                        </Label>
                        <Datepicker
                          name="date_first_recommendation"
                          id="date_first_recommendation"
                        />
                      </FormGroup>
                    </Col>
                    <Row className="my-3">
                      <Col md={6}>
                        <FormGroup name="investment_amount">
                          <Label htmlFor="investment_amount" required>
                            Valor Investimento
                          </Label>
                          <Number
                            name="investment_amount"
                            id="investment_amount"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="my-3">
                    <Col md={6}>
                      <FormGroup name="payment_exempted">
                        <Label htmlFor="payment_exempted" required>
                          Isento de pagamento
                        </Label>
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <Input
                            name="payment_exempted"
                            id="payment_exempted"
                            type="checkbox"
                            className="form-check-input"
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup name="status">
                        <Label htmlFor="status" required>
                          Status
                        </Label>
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <Input
                            name="status"
                            id="status"
                            type="checkbox"
                            className="form-check-input"
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
              <h2 className="my-10">Endereço</h2>
              <div className="separator my-4"></div>

              <Row className="my-3">
                <Col md={6}>
                  <FormGroup name="zipcode">
                    <Label htmlFor="zipcode" required>
                      CEP
                    </Label>
                    <Input
                      name="zipcode"
                      id="zipcode"
                      onBlur={(event) => onBlurZipcode(event, setFieldValue)}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup name="street">
                    <Label htmlFor="street" required>
                      Logradouro
                      <abbr title="required">*</abbr>
                    </Label>
                    <Input name="street" id="street" />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="my-3">
                <Col md={6}>
                  <FormGroup name="number">
                    <Label htmlFor="number" required>
                      Número
                    </Label>
                    <Input name="number" id="number" />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup name="complement">
                    <Label htmlFor="complement">Complemento</Label>
                    <Input name="complement" id="complement" />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="my-3">
                <Col md={6}>
                  <FormGroup name="neighborhood">
                    <Label htmlFor="neighborhood" required>
                      Bairro
                    </Label>
                    <Input name="neighborhood" id="neighborhood" />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup name="city">
                    <Label htmlFor="city" required>
                      Cidade
                    </Label>
                    <Input name="city" id="city" />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="my-3">
                <Col md={6}>
                  <FormGroup name="state">
                    <Label htmlFor="state" required>
                      Estado
                    </Label>
                    <Input name="state" id="state" />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup name="country">
                    <Label htmlFor="country" required>
                      País
                    </Label>
                    <Input name="country" id="country" />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Fechar
              </button>
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};
export default PersonalDataForm;
