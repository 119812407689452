import RemoteContent from "./remote_content";

export default class extends RemoteContent {
  static values = {
    contentData: {
      type: Object,
      default: {
        day: "",
        investments: [],
      },
    },
  };

  connect() {
    this.loadContent();
  }

  updateContentDataValue() {
    this.contentTarget.innerHTML = this.baseTemplate();
  }

  fillTableContent() {
    var contentHtml = "";
    this.contentDataValue.investments.map((content, index) => {
      contentHtml += this.contentTemplate(content, index);
    });

    return contentHtml;
  }

  baseTemplate() {
    return `
        <div class="col-md-6">
          <h4>Retornos Acumulados</h4>
          <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr>
                <th>Liquidez</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody data-liquidities-target='content'>
              ${this.fillTableContent()}
            </tbody>
          </table>
        </div
      `;
  }

  contentTemplate(data, index) {
    return `
          <tr data-bs-toggle="collapse" href="#liquidity-${index}"}>
            <td>D+${data.liquidity}</td>
            <td>${data.value.value}</td>
          </tr>
          <tr key="transactions_${index}" >
            <td>
              <table class="table table-striped table-row-bordered gy-5 gs-7 mb-1 collapse" id="liquidity-${index}"">
                <thead>
                  <tr>
                    <th>Investimento</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  ${this.investmentTemplate(data.value.investments)}
                </tbody>
              </table>
            </td>
          </tr>
          `;
  }

  investmentTemplate(investments, index) {
    var htmlBuilder = "";

    investments.map((investment) => {
      htmlBuilder += `
            <tr>
              <td>${investment.name}</td>
              <td>${investment.value}</td>
            </tr>
          `;
    });

    return htmlBuilder;
  }
}
