import axios, { getResponseError } from "../services/axiosRequest";

export const consolidatedPortfolio = (ctr, setter, setIsLoading) => {
  axios
    .get(`/consolidated_portfolios?ctr=${ctr}`)
    .then((result) => {
      setIsLoading(false);
      setter(result.data);
    })
    .catch((error) => {
      getResponseError(error);
      setIsLoading(false);
    });
};
