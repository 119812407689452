import masks from "./masks";
import validate from "./validate";
import dateRangepicker from "./datepicker";
import select2 from "./select2";
import zipcode from "./zipcode";
import editor from "./editor";
import confirmation from "./confirmation";

export default (jumpSelect2) => {
  masks();
  validate();
  dateRangepicker();
  if (!jumpSelect2) {
    select2();
  }

  zipcode();
  editor();
  confirmation();
};
