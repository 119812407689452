import $ from 'jquery'
import 'parsleyjs'
import 'parsleyjs/dist/i18n/pt-br'

const defaultOptions = {
  errorClass: 'is-invalid',
  successClass: '',
  errorsWrapper: '<span class="invalid-feedback"></span>',
  errorTemplate: '<div></div>',
  validators: {
    document: {
      fn: documentValidator,
      priority: 32
    }
  }
}

const validate = () => {
  $('#form-for-validation, .form-for-validation').parsley(defaultOptions)
}

const documentValidator = (val, req) => {
  val = $.trim(val);

  val = val.replace('.', '');
  val = val.replace('.', '');
  cpf = val.replace('-', '');
  while (cpf.length < 11) cpf = "0" + cpf;
  var expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/;
  var a = []
  var b = new Number
  var c = 11
  for (i = 0; i < 11; i++) {
    a[i] = cpf.charAt(i)
    if (i < 9) b += (a[i] * --c)
  }
  if ((x = b % 11) < 2) { a[9] = 0 } else { a[9] = 11 - x }
  b = 0
  c = 11
  for (y = 0; y < 10; y++) b += (a[y] * c--)
  if ((x = b % 11) < 2) { a[10] = 0; } else { a[10] = 11 - x; }

  var result = true
  if ((cpf.charAt(9) != a[9]) || (cpf.charAt(10) != a[10]) || cpf.match(expReg)) result = false

  return result;

}

export default validate
