import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, ModalBody, ModalFooter } from "reactstrap";
import { Formik, Form, Field } from "formik";
import userHistory from "../../schemas/userHistory.schema";

import { GlobalContext } from "../contexts/GlobalState";
import {
  Select,
  Input,
  Label,
  FormGroup,
  Number,
  Datepicker,
} from "./controls";
import incomesSchema from "../../schemas/incomes.schema";

const FormCreateClientRegister = ({ onFormSubmit, closeModal }) => {
  const { states, setters } = useContext(GlobalContext);

  const onSubmit = (values) => {
    onFormSubmit({
      ...values,
      kind: values.kind.value,
    });
  };

  const initialValues = {
    day: "",
    description: "",
    kind: "",
  };

  const types = [
    {
      value: "note",
      label: "Nota",
    },
    {
      value: "churn",
      label: "Desativar Cliente",
    },
    {
      value: "client_return",
      label: "Retorno de cliente",
    },
  ];

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={userHistory}
        onSubmit={onSubmit}
      >
        <Form>
          <ModalBody className="container p-10">
            <Row className="my-3 d-flex justify-content-center">
              <Col md={5}>
                <FormGroup name="day">
                  <Label htmlFor="day" required>
                    Data
                  </Label>
                  <Datepicker name="day" id="day" />
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup name="kind">
                  <Label htmlFor="kind" required>
                    Tipo
                  </Label>
                  <Select name="kind" id="kind" options={types} />
                </FormGroup>
              </Col>
            </Row>

            <Row className=" my-3 d-flex justify-content-center">
              <Col md={10}>
                <FormGroup name="description">
                  <Label htmlFor="description" required>
                    Texto
                    <abbr title="required">*</abbr>
                  </Label>
                  <Input
                    type="textarea"
                    rows="7"
                    name="description"
                    id="description"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={closeModal}
            >
              Fechar
            </button>
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </ModalFooter>
        </Form>
      </Formik>
    </Fragment>
  );
};
export default FormCreateClientRegister;
