import React, { useEffect, useState, useContext, Fragment } from "react";
import * as S from "../../style/styles";
import { GlobalContext } from "../contexts/GlobalState";
import { Loader } from "../elements/Loader";
import TableRemoteContent from "../tables/TableRemoteContent";
import ModalForm from "../modals/ModalForm";

import LiquidityDetail from "../elements/LiquidityDetails";
import FormCreateClientRegister from "../forms/ClientHistoryForm";
import { createClientRegister } from "../../services/userProfileRequests";

const ClientHistory = ({ active, userId, isLoading }) => {
  const { states, setters } = useContext(GlobalContext);
  const { userLiquidity, clientHistory } = states;
  const { setClientHistory } = setters;
  const tabValue = 13;
  const isActive = active === tabValue;
  const [isModalOpen, setIsModalOpen] = useState(false);


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const creatAction = (values) => {
    createClientRegister(userId, afterSubmitForm, values)
  };

  const afterSubmitForm = (data) => {
    setIsModalOpen(false);
    setClientHistory(data);
  };

  return (
    <S.DisplayContainer isActive={isActive} isLoading={isLoading}>
      <Loader />

      <div className="content-display">
        <TableRemoteContent
          cardTitle="Histórico do cliente"
          isEditable={true}
          actionType={'create'}
          setIsModalOpen={setIsModalOpen}

        >
          <Fragment>
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <tbody>
                <tr>
                  <td>Data</td>
                  <td>Texto</td>
                  <td>Tipo</td>
                  <td>Criado por</td>
                  <td>Criado em</td>
                </tr>
                {clientHistory &&
                  clientHistory.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.day}</td>
                        <td>{item.description}</td>
                        <td>{item.kind}</td>
                        <td>{item.author.name}</td>
                        <td>{item.updated_at}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <ModalForm isOpen={isModalOpen} toggle={toggleModal} label="Novo Registro">
              <FormCreateClientRegister onFormSubmit={creatAction} closeModal={() => toggleModal()} />
            </ModalForm>
          </Fragment>
        </TableRemoteContent>
      </div>


    </S.DisplayContainer>
  );
};

export default ClientHistory;
