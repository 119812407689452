import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const ModalForm = ({ label, toggle, isOpen, children }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        <i className="icon-list3" />
        {label}
      </ModalHeader>
      {children}
    </Modal>
  );
};

export default ModalForm;
