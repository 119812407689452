import React from "react";
import { SwalButton } from "../buttons";

const TableRemoteContent = ({
  cardTitle,
  children,
  isEditable,
  setIsModalOpen,
  actionType,
  swalFunction
}) => {

  const headerIcon = () => {
    switch (actionType) {
      case 'create':
        return <i className="bi bi-plus fs-3"></i>
        break;
      case 'edit':
        return <i className="bi bi-pencil-square fs-7" />
        break;
      case 'altercharge':
        return (
          <SwalButton
            className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
            onSuccess={swalFunction}
            swalProps={{
              title: "Você realmente deseja cobrar manualmente o cliente?",
            }}
          >
            <i className="bi bi-plus fs-3"></i>
          </SwalButton>)
        break;
      default:
        break;
    }
  }

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-header  border-bottom border-gray-200 pt-5">
        <div className="card-title align-items-start flex-column">
          <h3 className="fw-bolder m-0">{cardTitle}</h3>
        </div>
        {isEditable ? (
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
              onClick={actionType !== 'altercharge' ? () => {
                setIsModalOpen(true);
              } : null}
            >
              {headerIcon()}
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="card-body p-9 ">
        <div className="">{children}</div>
      </div>
    </div>
  );
};

export default TableRemoteContent;
